<template>
  <div>

  <v-container fluid>  
    <v-card  elevation="4" >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="nome_participante" dense hide-details outlined label="Nome" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="cpf_participante" dense hide-details outlined label="CPF" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>  
  
    <br>
  
    <v-row v-if="loading" class="mt-5">
      <v-col>
        <div class="text-center" >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>  
    <v-row v-if="loading" >
      <v-col>
        <div class="text-center" >Carregando</div>
      </v-col>
    </v-row> 
    <v-form ref="form2" v-model="form_valido" lazy-validation>  
    <v-card v-if="!loading && !selecionar_arquivo">
      <v-card-text >
        <v-row>
          <v-col class="text-center mt-5">
            <h1 >Selecione o tipo de Documento para anexar um arquivo</h1>
          </v-col>
        </v-row>
      </v-card-text >  
      <v-card-text >
        <v-form ref="form" v-model="form_valido" lazy-validation>  
          <v-radio-group v-model="id_tipo_documento" :rules="Rules.Tipo" :readonly="somente_leitura">
            <v-row>
              <v-col>
                <v-radio
                  v-for="tipo_documento in FiltraTipoDocumento(0)"
                  :key="tipo_documento.id"
                  :label="tipo_documento.descricao"
                  :value="tipo_documento.id"
                  class="pa-2"
                  :off-icon="tipo_documento.id == id_tipo_documento ? '$radioOn' : '$radioOff'"
                ></v-radio>
              </v-col>  
              <v-col>
                <v-radio
                  v-for="tipo_documento in FiltraTipoDocumento(1)"
                  :key="tipo_documento.id"
                  :label="tipo_documento.descricao"
                  :value="tipo_documento.id"
                  class="pa-2"
                  :off-icon="tipo_documento.id == id_tipo_documento ? '$radioOn' : '$radioOff'"
                ></v-radio>
              </v-col>  
              <v-col>  
                <v-radio
                  v-for="tipo_documento in FiltraTipoDocumento(2)"
                  :key="tipo_documento.id"
                  :label="tipo_documento.descricao"
                  :value="tipo_documento.id"
                  class="pa-2"
                  :off-icon="tipo_documento.id == id_tipo_documento ? '$radioOn' : '$radioOff'"
                ></v-radio>
              </v-col >  
            </v-row>
          </v-radio-group>
        </v-form>     
      </v-card-text>
       <v-card-text >
        <v-row>
          <v-col class="text-center mt-5">
            <v-file-input
              truncate-length="150"
              placeholder="Arquivo"
              show-size
              accept='.pdf'
              v-model="arquivo"
              id="fileUpload1"
              :rules="Rules.Arquivo"
            ></v-file-input>
          </v-col>
        </v-row>    
      </v-card-text> 
    </v-card>
    </v-form>
    <br>
  
    <v-row>
      <v-col class="text-right">
        <v-btn rounded class="mr-3" color="#B0BEC5"   @click="selecionarArquivo()"  v-if="!arquivo && !loading && !dialog"> <v-icon>mdi-file-outline </v-icon> Selecionar arquivo </v-btn>
        <v-btn rounded class="mr-3" color="green"   @click="gravar()"  v-if="arquivo && !loading "> <v-icon>mdi-content-save</v-icon> Gravar </v-btn>
        <v-btn rounded  color="#B0BEC5"   to="/" v-if="!selecionar_arquivo && !loading && !dialog && (typeof this.$route.params.id == 'undefined')"> <v-icon>mdi-arrow-left-circle</v-icon> Voltar </v-btn>
        <v-btn rounded  color="#B0BEC5"   :to="'/requerimento/'+this.$route.params.requer" v-if="!selecionar_arquivo && !loading && !dialog && (typeof this.$route.params.id != 'undefined')"> <v-icon>mdi-arrow-left-circle</v-icon> Voltar </v-btn>
        
      </v-col>
    </v-row> 
  
    <v-overlay
      :absolute="absolute"
      :value="dialog"
    >
    </v-overlay>
  
    <v-snackbar
      v-model="dialog"
      :multi-line="multiLine"
      centered
      :color="cor_dialogo"
      timeout="-1"
      max-width="400"
      min-height="140"
    >
      {{ mensagem }}
  
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnModal(titulo)"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  
  </v-container>
  </div>
</template>

<script>
   export default {

      data: function(){
         return {
            Rules:{
              Tipo: [v => !!v || 'Escolha o tipo de documento que você vai enviar',],
              Arquivo: [v => !!v || 'Escolha um arquivo para enviar',]
            },
            multiLine: true,
            overlay:false,
            absolute: false,
            form_valido:true,
            loading: false,
            mensagem: '',
            dialog: false,
            titulo: null,
            cor_dialogo: '#455A64',
            //cor_dialogo: '#B00020',
            reverse: false,
            selecionar_arquivo: false,
            nome_participante: '',
            cpf_participante: '',
            id_tipo_documento: null,
            arquivo: null,
            noFile: true,
            tipos_documento: this.$store.state.tipos_documento,
            somente_leitura: false,
            respostas:[
                        {id:1, descricao:"Acatado", icone:"mdi-check-circle", color: "green"},
                        {id:2, descricao:"Ilegível", icone:"mdi-close-circle", color: "red"},
                        {id:3, descricao:"Incorreto", icone:"mdi-close-circle", color: "red"},
                      ], 
         }
      },
      created: function() {
        
        var texto = '<p style="color:white; font-size: 12px;">- Selecione o tipo de documento que deseja enviar ao Serpros. </p>'+
                    '<p style="color:white;font-size: 12px;">- Em seguida clique no botão <b>Selecionar arquivo</b>. </p>'+
                    '<p style="color:white;font-size: 12px;">- Quando terminar de selecionar o documento, clique no botão Gravar.</p>';

        this.$store.dispatch('exibeajuda',texto);

        this.nome_participante = this.$store.state.nome;
        this.cpf_participante = this.$store.state.cpf;

        if(typeof this.$route.params.id != 'undefined'){
            this.id_tipo_documento = this.$route.params.id;
            this.somente_leitura = true;
        } 

       // console.log(this.FiltraTipoDocumento(0));
      },
      methods: {

        //#####################
        //Filtro de tipos de documento
        //####################
        FiltraTipoDocumento: function(coluna){
          var qtd_total = this.tipos_documento.length;
          var qtd_por_coluna = Math.ceil(qtd_total / 3);
          var primeiro_indice = coluna * qtd_por_coluna;
          var ultimo_indice = primeiro_indice + (qtd_por_coluna -1);
          var primeiro_id = 0;
          var ultimo_id = 0;
          

          if(primeiro_indice < qtd_total){
             primeiro_id = this.tipos_documento[primeiro_indice].id;
          }

          if(ultimo_indice < qtd_total){
             ultimo_id = this.tipos_documento[ultimo_indice].id;
          }else{
             if(primeiro_id != 0){
                ultimo_id = this.tipos_documento[qtd_total-1].id;
             }
          }


          return this.tipos_documento.filter(function(tipo) {
            var exibe = false;
            if(tipo.id >= primeiro_id && tipo.id <= ultimo_id) {
              exibe = true;
            }
            return exibe;
          })
        },
        //#####################
        //click dos botões da tela
        //####################
        
        BtnModal(titulo){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          if(titulo == 'Sessão Expirada' || titulo == 'Alerta'){
            this.$router.push('/login');
          } 

          if(titulo == 'Sucesso' ){
            this.$router.push('/');
          }
           
        },         


        selecionarArquivo() {
          //console.log(this.id_tipo_documento);
          this.form_valido = false;
          if(this.$refs.form.validate() ){
            document.getElementById("fileUpload1").click();
          }  
        },


        gravar() {

          this.form_valido = false;
          if(this.$refs.form2.validate() ){
            this.form_valido = false;
            if(this.$refs.form.validate() ){

              this.loading = true;

              let formData = new FormData();
              formData.append('id_nuvem', this.$store.state.nuvem.id);
              formData.append('id_tipo', 405);
              formData.append('id_tipo_documento',this.id_tipo_documento);
              formData.append('id_protocolo_ged',this.$store.state.nuvem.id_protocolo_ged);
              formData.append('token_ged',this.$store.state.token_ged);
              formData.append('arquivo', this.arquivo);
              formData.append('usuario', this.$store.state.cpf);
              formData.append('nome', this.$store.state.nome);
              formData.append('id_origem', 1);
              formData.append('escopo', 'P');
        
              console.log(formData); 

              this.$store.state.API.post( '/api/novodocumento',  formData)
              .then(response => {
                if(response.data.codigo == 'ok'){
                  this.arquivo = null;

                  if(typeof this.$route.params.id != 'undefined'){
                    this.atualizaDocumento(this.$route.params.id_req_doc, response.data.id_documento);
                  }else{
                    this.$store.commit('setalerta','Documento enviado com sucesso!');
                    this.$router.push('/');
                  }  

                }
              })
              .catch(error => {
                  this.TrataErros(error);

              });
            }
          }  
        },

        gravar2() {

          this.form_valido = false;
          if(this.$refs.form2.validate() ){
            this.form_valido = false;
            if(this.$refs.form.validate() ){

              this.loading = true;

              let gedData = new FormData();
              gedData.append('id_usuario', 53657);
              gedData.append('id_tipo', 405);
              gedData.append('id_protocolo',this.$store.state.nuvem.id_protocolo_ged)
              gedData.append('arquivo', this.arquivo);

              //console.log(gedData);
             

              this.$store.state.API.postFile( '/api/documentos',  gedData)
              .then(response => {

                  let formData = new FormData();
                  formData.append('id_nuvem', this.$store.state.nuvem.id);
                  formData.append('id_tipo_documento',this.id_tipo_documento);
                  formData.append('id_protocolo_ged',this.$store.state.nuvem.id_protocolo_ged);
                  formData.append('usuario', this.$store.state.cpf);
                  formData.append('arquivo', response.data.documento.arquivo);
                  formData.append('id_documento_ged', response.data.documento.id);
                  formData.append('id_origem', 1);
                  formData.append('escopo', 'P');

                  this.$store.state.API.post( '/api/novodocumento',  formData)
                  .then(response => {
                    if(response.data.codigo == 'ok'){
                      this.arquivo = null;

                      if(typeof this.$route.params.id != 'undefined'){
                        this.atualizaDocumento(this.$route.params.id_req_doc, response.data.id_documento);
                      }else{
                        this.$store.commit('setalerta','Documento enviado com sucesso!');
                        this.$router.push('/');
                      }  

                    }
                  })
                  .catch(error => {
                      this.TrataErros(error);

                  });

              })
              .catch(error => {
                  this.TrataErros(error);

              });

              
            }
          }  
        },


         
        atualizaDocumento(id_req_doc, id_documento){


          let data = {
                id_req_doc: id_req_doc,
                id_documento: id_documento,
                id_situacao: 4,
                usuario: this.$store.state.cpf,
                id_nuvem: this.$store.state.nuvem.id
              };

          this.$store.state.API.post( '/api/requerimento/atualizadocumento',  data)
          .then(response => {
              if(response.data.codigo == 'ok'){

                this.$store.commit('setalerta','Documento enviado com sucesso!');
                this.$router.push('/requerimento/'+this.$route.params.requer);
        
              }else{
                this.loading = false;
              }
              
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },
        //#####################
        //funções utilitárias
        //####################

        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 
        

      },
   }
</script>