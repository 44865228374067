var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":"2000","color":"green"},model:{value:(_vm.exibe_alerta),callback:function ($$v) {_vm.exibe_alerta=$$v},expression:"exibe_alerta"}},[_vm._v(" "+_vm._s(_vm.alerta)+" ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","label":"Nome","readonly":""},model:{value:(_vm.nome_participante),callback:function ($$v) {_vm.nome_participante=$$v},expression:"nome_participante"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","label":"CPF","readonly":""},model:{value:(_vm.cpf_participante),callback:function ($$v) {_vm.cpf_participante=$$v},expression:"cpf_participante"}})],1)],1)],1)],1),_c('br'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.form_valido),callback:function ($$v) {_vm.form_valido=$$v},expression:"form_valido"}},[(_vm.loading)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)])],1):_vm._e(),(_vm.loading)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center"},[_vm._v("Carregando")])])],1):_vm._e(),(_vm.autorizado && !_vm.loading)?_c('v-card',{attrs:{"outlined":"","elevation":"4"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"text-left"},[_c('h4',{staticClass:"text--secondary"},[_vm._v(" Requerimentos")])])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-2 table",attrs:{"headers":_vm.header,"items":_vm.dados,"sort-by":"data_requerimento","sort-desc":"","no-data-text":"Não há nenhum requerimento","items-per-page":10,"loading":_vm.loading.anexo,"loading-text":"Carregando requerimentos...","footer-props":{
                    itemsPerPageText: 'Linhas por página',
                    itemsPerPageAllText: 'Todas',
                  }},scopedSlots:_vm._u([{key:"item.data_requerimento",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.data_requerimento).toLocaleString("pt-BR",{dateStyle: "short"})))])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(item.situacao === 'E')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange","elevation":"4"}},[_vm._v("mdi-alert-circle-outline")]):(item.situacao === 'D')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","elevation":"4"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","elevation":"4"}}),((item.situacao == 'L' || item.situacao == 'E' || item.situacao == 'X') && item.id_tipo!=14)?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.ChamaFormulario(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(item.id_tipo==14)?_c('v-tooltip',{attrs:{"color":"rgba(0, 0, 0, 1)","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_vm._v(" Este requerimento só pode ser acessado pela área do participante no menu Cadastro/Recadastramento ")]):_vm._e()]}}],null,true)})],1)],1):_vm._e(),_c('br'),(!_vm.autorizado && !_vm.loading)?_c('v-card',[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-checkbox',{attrs:{"label":"Concordo em disponibilizar as cópias dos meus documentos para uso do Serpros."},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1)],1):_vm._e(),_c('br'),(!_vm.loading)?_c('v-row',[_c('v-col',{staticClass:"text-right"},[(_vm.resposta )?_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","color":"primary"},on:{"click":_vm.autoriza}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" Gravar")],1):_vm._e(),(_vm.autorizado && !_vm.loading)?_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","color":"#B0BEC5","to":"/tiposreq"}},[_c('v-icon',[_vm._v("mdi-file-outline")]),_vm._v(" Novo Requerimento ")],1):_vm._e(),(!_vm.loading)?_c('v-btn',{attrs:{"rounded":"","color":"#B0BEC5"},on:{"click":_vm.perguntaSair}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle")]),_vm._v(" Sair")],1):_vm._e()],1)],1):_vm._e(),_c('v-overlay',{attrs:{"absolute":"","value":_vm.dialog}}),_c('v-snackbar',{attrs:{"multi-line":"","centered":"","color":_vm.cor_dialogo,"timeout":"-1","max-width":"400","min-height":"140","vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [(!_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnOk()}}},'v-btn',attrs,false),[_vm._v(" Ok ")]):_vm._e(),(_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnSim()}}},'v-btn',attrs,false),[_vm._v(" Sim ")]):_vm._e(),(_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnNao()}}},'v-btn',attrs,false),[_vm._v(" Não ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "+_vm._s(_vm.mensagem)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }