import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js'

//import Home from '@/pages/Home'
import Novo from '@/pages/Novo'
import NovoComplementar from '@/pages/NovoComplementar'
import Login from '@/pages/Login'
import Requerimentos from '@/pages/Requerimentos'
import Requerimento01 from '@/pages/Requerimento01'
import Requerimento02 from '@/pages/Requerimento02'
import Requerimento03 from '@/pages/Requerimento03'
import Requerimento05 from '@/pages/Requerimento05'
import DocsPorTipo from '@/pages/DocsPorTipo'
import DocsBnfPorTipo from '@/pages/DocsBnfPorTipo'
import NaoTemSenha from '@/pages/NaoTemSenha'
import Requerimento06 from '@/pages/Requerimento06'

import Docs from '@/pages/Docs'
import TiposReq from '@/pages/TiposReq'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'requerimentos',
            component: Requerimentos
        },
        {
          path: '/novo/:id?/:requer?/:id_req_doc?',
          name: 'novo',
          component: Novo
        },
        {
          path: '/novocomplementar/:id?',
          name: 'novocomplementar',
          component: NovoComplementar
        },
        {
          path: '/requerimento01/:id?/:id_tipo_req?/:etapa?',
          name: 'requerimento01',
          component: Requerimento01
        },
        {
          path: '/requerimento02/:id?/:id_tipo_req?/:etapa?',
          name: 'requerimento02',
          component: Requerimento02
        },
        {
          path: '/requerimento03/:id?/:id_tipo_req?',
          name: 'requerimento03',
          component: Requerimento03
        }, 
        {
          path: '/requerimento05/:id?/:id_tipo_req?',
          name: 'requerimento05',
          component: Requerimento05
        },
        {
          path: '/requerimento06/:id?',
          name: 'requerimento06',
          component: Requerimento06
        },
        {
            path: '/login/:code?',
            name: 'login',
            component: Login
        },
        {
            path: '/docsportipo/:id_tipo_documento/:id_requerimento/:id_req_doc/:tipo_requerimento',
            name: 'docsportipo',
            component: DocsPorTipo
        },
        {
          path: '/docs/:id_requerimento?',
          name: 'docs',
          component: Docs
        },
        {
            path: '/naotemsenha',
            name: 'naotemsenha',
            component: NaoTemSenha
        },
        {
          path: '/tiposreq',
          name: 'tiposreq',
          component: TiposReq
        },
        {
          path: '/docsbnfportipo/:id_tipo_documento/:id_requerimento/:id_req_bnf_doc/:id_dependente/:tipo_requerimento',
          name: 'docsbnfportipo',
          component: DocsBnfPorTipo
        }
    ]
});



router.beforeEach((to,from,next) => {


    if (to.name !== 'login' && to.name !== 'naotemsenha' && !store.state.usuario.logado ) {
      //console.log('Router jogou pro login');
      next({ name: 'login' });
    }else{
      //console.log('Router jogou pro '+to.name);
      next();
    }


  
});


export default router