import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from 'vue-axios'
import axios from 'axios'
//import router from './routes'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

//###############################
// A função API é uma "closure"
// O objetivo dessa closure é guardar o token de acesso 
// em uma variável que não pode ser acessada fora dessa função
// e dessa forma proteger o token contra ataques de XSS
//###############################

function API(){

  const meuaxios = axios;

  let token = {
    access_token: null,
    
  };

  let token_ged = {
    access_token: null,
    
  };

  let apipartic = process.env.VUE_APP_API;
  let apiged =  process.env.VUE_APP_APIGED;


  return {
    Iniciar: function(value){
      token = value;
    },
    setTokenGed: function(value){
      token_ged.access_token = value;
    },
    get: function(url){
      return  meuaxios({
        method: 'get',
        url: apipartic+url,
        headers: { 'Authorization': 'Bearer '+ token.access_token },
        withCredentials: true
      });
    },
    post: function(url, data){
      return  meuaxios({
        method: 'post',
        url: apipartic+url,
        data: data,
        headers: { 'Authorization': 'Bearer '+ token.access_token },
        withCredentials: true
      });
    },
    getFile: function(id_documento, id_protocolo){
      return  meuaxios({
        method: 'get',
        url: apiged+'/documento/'+id_documento+'/'+id_protocolo+'/download',
        responseType: 'blob',
      },{responseType: 'arraybuffer'});
    },
    postFile: function(url, data){
      return  meuaxios({
        method: 'post',
        url: apiged+url,
        data: data,
        headers: { 'Authorization': 'Bearer '+ token_ged.access_token },
        withCredentials: true
      });
    },
  }
}

const api = API();

const store = new Vuex.Store({
  state: {
    API: api,
    usuario: {
      logado: false,
      nome: '',
      id: '',
      email: '',
      matricula: '',
      id_setor: ''
    },
    urls: {
     self: process.env.VUE_APP_URL,
     ged: process.env.VUE_APP_APIGED,
    },
    credentials: {
      //client_id: '0efae58202bc8a75'
      client_id: process.env.VUE_APP_CLIENTID
    },
    //id_sistema: 54172,
    id_sistema: process.env.VUE_APP_IDSISTEMA,
    cpf: null,
    nome: null,
    id_pessoa_fisica: null,
    data_nascimento: null,
    dt_ini_plano: null,
    ajuda: '',
    nuvem: null,
    planos: null,
    beneficios: null,
    situacoes: null,
    tipos_documento: null,
    tipos_req: null,
    docs_obrigatorios: null,
    plano_participante: null,
    desligado_antes_1996: false,
    bancos: [
      {numero:"001", nome: "BANCO DO BRASIL"},
      {numero:"104", nome: "CAIXA ECONÔMICA FEDERAL"},
      {numero:"237", nome: "BRADESCO"},
      {numero:"033", nome: "SANTANDER"}
    ],
    tipos_pagamento: [
      {id:1, descricao: "Crédito em conta corrente"},
      {id:2, descricao: "Crédito em conta salário"},
    ],
    alerta: '',
    endereco: null,
    Intervalo: null,
    dataLimite: null,
    tempoRestante: 3600,
    max_file_size: 5,
    permissao_resgate: ''
  },
  mutations: {
    setusuariologado(state, p1){
      state.usuario = p1;
    },
    setajuda(state, p1){
      state.ajuda = p1; 
    },
    setcpf(state, p1){
      state.cpf = p1; 
    },
    setnome(state, p1){
      state.nome = p1; 
    },
    setid_pessoa_fisica(state, p1){
      state.id_pessoa_fisica = p1; 
    },
    setnuvem(state, p1){
      state.nuvem = p1; 
    },
    setplanos(state, p1){
      state.planos = p1; 
    },
    setbeneficios(state, p1){
      state.beneficios = p1; 
    },
    setsituacoes(state, p1){
      state.situacoes = p1; 
    },
    settipos_documento(state, p1){
      state.tipos_documento = p1; 
    },
    settipos_req(state, p1){
      state.tipos_req = p1; 
    },
    setendereco(state, p1){
      state.endereco = p1; 
    },
    setdataNascimento(state, p1){
      state.data_nascimento = p1; 
    },
    setdtIniPlano(state, p1){
      state.dt_ini_plano = p1; 
    },
    setPlanoParticipante(state, p1){
      state.plano_participante = p1; 
    },
    setmaxfilesize(state, p1){
      state.max_file_size = p1; 
    },
    setdocs_obrigatorios(state, p1){
      state.docs_obrigatorios = p1; 
      var tipo_documento = null;
      for (var i = 0; i < state.docs_obrigatorios.length; i++) {
        tipo_documento = state.tipos_documento.find(tipo_documento => tipo_documento.id ==state.docs_obrigatorios[i].id_tipo_documento);
        state.docs_obrigatorios[i].desc_tipo_documento = tipo_documento.descricao;
        state.docs_obrigatorios[i].id_tipo_documento = parseInt(state.docs_obrigatorios[i].id_tipo_documento);
      }
    },
    setalerta(state, p1){
      state.alerta = p1; 
    },
    setPermissaoResgate(state, p1){
      state.permissao_resgate = p1; 
    },
    setDesligado1996(state, p1){
      state.desligado_antes_1996 = p1; 
    },
  },
  actions: {
    
    exibeajuda(context,ajuda){
      context.commit('setajuda',ajuda);
    },

    iniciarRelogio(context){
      context.state.dataLimite = new Date();
      context.state.dataLimite.setHours(context.state.dataLimite.getHours() + 1);

      clearInterval(context.state.Intervalo);

      context.state.Intervalo = setInterval(function() {

        var dataAtual = new Date();
        
        if(context.state.dataLimite > dataAtual){
          context.state.tempoRestante = Math.floor(Math.abs(context.state.dataLimite-dataAtual)/1000);
        }else{
          context.state.tempoRestante = 0;
          clearInterval(context.state.Intervalo);
        }

      }, 1000);
    }
    
  }
})

export default store