<template>
  <div>
    <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="11" class="text-left">
                  <h2> Endereço de correspondência </h2> 
              </v-col>
              
            </v-row>
          </v-card-text>
          <br>
          <v-card-text>
          <div>
            <v-row class="mb-n5">
              <v-col cols="12" md="3">
                <v-select
                  label="País"
                  :value="endereco.id_pais"
                  @input="setPais"
                  :items="paises"
                  item-text="nome"
                  item-value="id"
                  
                  dense
                  :rules="Rules.Required"
                  
                > 
                </v-select>
              </v-col>  
              <v-col cols="12" md="2">
                <v-text-field 
                    :disabled="!mora_no_brasil"
                    dense 
                    :value="endereco.cep" 
                    @input="setCep"
                    label="CEP"  
                    :rules="[...maxLength(8), ...cep(endereco.cep)]" 
                    append-outer-icon="mdi-text-search" 
                    @click:append-outer="consultaCEP(endereco.cep)"
                    maxlength="8"
                    >
                    
                  </v-text-field>  
              </v-col>   
              <v-col cols="12" md="2">
                <v-select
                  :disabled="!mora_no_brasil"
                  label="Estado"
                  :value="endereco.estado"
                  @input="setEstado"
                  :items="estados"
                  dense
                  :rules="[...Estado]"
                  autocomplete="none" 
                > 
                </v-select>
              </v-col>  
              <v-col cols="12" md="5"> 
                <v-text-field 
                    dense 
                    :value="endereco.cidade" 
                    @input="setCidade"
                    label="Cidade"  
                    :rules="[...Rules.Required, ...maxLength(30)]"
                    autocomplete="none" 
                ></v-text-field>
              </v-col>  



            </v-row>  

            <v-row class="mb-n5">

              <v-col cols="12" md="3">
                <v-text-field  
                  dense 
                  :value="endereco.bairro" 
                  @input="setBairro"
                  label="Bairro"  
                  :rules="[...Rules.Required, ...maxLength(20)]"
                  autocomplete="none" 
                ></v-text-field>
              </v-col>  
              
              <v-col cols="12" md="4"> 
                <v-text-field 
                  dense 
                  :value="endereco.endereco"
                  @input="setEndereco" 
                  label="Endereço"  
                  :rules="[...Rules.Required, ...maxLength(120)]"
                  autocomplete="none" 
                ></v-text-field>
              </v-col> 
              <v-col cols="12" md="2"> 
                <v-text-field 
                  dense 
                  :value="endereco.numero" 
                  @input="setNumero"
                  label="Número"  
                  :rules="[...Rules.Required, ...maxLength(10)]"
                  autocomplete="none" 
                ></v-text-field>
              </v-col>  
              <v-col cols="12" md="3"> 
                <v-text-field 
                  dense 
                  :value="endereco.complemento" 
                  @input="setComplemento"
                  label="Complemento" 
                  :rules="[...maxLength(40)]"
                  autocomplete="none" 
                ></v-text-field>
              </v-col> 

            </v-row>

            <v-row class="mb-n5">
              <v-col cols="12" md="3">
                <v-text-field 
                  autocomplete="none" 
                  dense 
                  :value="endereco.email_pessoal" 
                  @input="setEmailPessoal"
                  label="* Email Pessoal"  
                  :rules="[...Rules.Required, ...maxLength(60), ...email(endereco.email_pessoal)]"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" md="3">
                <v-text-field 
                  autocomplete="none" 
                  dense 
                  :value="endereco.email_extra" 
                  @input="setEmailExtra"
                  label="Email Pessoal Extra" 
                  :rules="[...maxLength(60), ...email(endereco.email_extra)]"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" md="3">
                <v-text-field 
                  dense 
                  autocomplete="none" 
                  :value="endereco.celular" 
                  @input="setCelular"
                  label="* Celular"  
                  :rules="[...Rules.Required, ...maxLength(15), ...telefone(endereco.celular)]"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" md="3">
                <v-text-field 
                  dense 
                  autocomplete="none" 
                  :value="endereco.telefone_alternativo" 
                  @input="setTelefoneAlternativo"
                  label="Telefone Alternativo" 
                  :rules="[...maxLength(15)]" 
                ></v-text-field>
              </v-col> 

            </v-row>
          </div>  
          </v-card-text>
    </v-card>    
  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqEndereco",
        props: ['value','situacao', 'paises'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            endereco: this.value,
            mora_no_brasil: true,
            sexo:[
              {id:'F', descricao:'Feminino'},
              {id:'M', descricao:'Masculino'},
            ],
            dependencia: [
              {id:1, descricao:'Cônjuge ou ex-cônjuge pensionista'},
              {id:2, descricao:'Companheiro(a) em união estável'},
              {id:3, descricao:'Ex-companheiro(a) pensionista'},
              {id:4, descricao:'Filhos, enteados e tutelados solteiros e menores de 21 anos'},
              {id:5, descricao:'Filhos, enteados e tutelados solteiros e inválidos'},
            ],
            estados:["AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
            
          }
        },  
        created: function() {
          if(this.endereco.id_pais == 31){
            this.mora_no_brasil = true;
          }else{
            this.mora_no_brasil = false;
          }
        },
        methods: {
          maxLength: function(max) {
            return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
          },

          cep: function(cep) {
          
            let aprovado = true;
            let mensagem = '';

            if(this.endereco.id_pais == 31){

              if(cep.length < 8){
                aprovado = false;
                mensagem = 'Mínimo 8 números';
              }

              if(cep.length < 1){
                aprovado = false;
                mensagem = 'Obrigatório';
              }

              if(cep.indexOf('0000000') != -1 || 
                cep.indexOf('1111111') != -1 || 
                cep.indexOf('2222222') != -1 || 
                cep.indexOf('3333333') != -1 || 
                cep.indexOf('4444444') != -1 || 
                cep.indexOf('5555555') != -1 || 
                cep.indexOf('6666666') != -1 || 
                cep.indexOf('7777777') != -1 || 
                cep.indexOf('8888888') != -1 || 
                cep.indexOf('9999999') != -1 || 
                cep.indexOf('1234567') != -1
                ){

                aprovado = false;
                mensagem = 'CEP inválido';
              }
            }

            return  aprovado || mensagem
          },


          email: function(email) {

            var resultado = true;
            var mensagem = '';

            if (email != null && email != '' ){
              var upper_email = email.toUpperCase();
              
              if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(upper_email))
              {
                resultado = false;
                mensagem = 'E-mail inválido';
              }



              if( upper_email.indexOf("SERPROS.COM") != -1 || upper_email.indexOf(".GOV.BR") != -1 ){
                resultado = false;
                mensagem = 'use seu e-mail particular';
              }
              
            }
            
            
            return  resultado || mensagem
          },

          Estado: function(){

            let resultado = true;
            let mensagem = '';

            if(this.endereco.id_pais == 31){
              
              if(this.endereco.estado == ''){
                  resultado = false;
                  mensagem = 'Obrigatório';
              }
            }

            return resultado || mensagem 
          },


          telefone: function(telefone) {
          
            let aprovado = true;
            let mensagem = '';

            if(telefone != null){

              if(telefone.length < 10){
                aprovado = false;
                mensagem = 'Mínimo: ddd + 8 números';
              }

              if(telefone.indexOf('0000000') != -1 || 
                telefone.indexOf('1111111') != -1 || 
                telefone.indexOf('2222222') != -1 || 
                telefone.indexOf('3333333') != -1 || 
                telefone.indexOf('4444444') != -1 || 
                telefone.indexOf('5555555') != -1 || 
                telefone.indexOf('6666666') != -1 || 
                telefone.indexOf('7777777') != -1 || 
                telefone.indexOf('8888888') != -1 || 
                telefone.indexOf('9999999') != -1 || 
                telefone.indexOf('1234567') != -1
                ){

                aprovado = false;
                mensagem = 'Número inválido';
              }
            }  

            return  aprovado || mensagem
          },

          consultaCEP(cep) {
            //busca o cep
            if(cep.length == 8){
              this.axios({
                method: 'get',
                url: 'https://viacep.com.br/ws/'+cep+'/json/',
              })
              .then(response => {
                          if("cep" in response.data){
                              this.endereco.endereco = response.data.logradouro.toUpperCase();
                              this.endereco.estado = response.data.uf;
                              this.endereco.cidade = response.data.localidade.toUpperCase();
                              this.endereco.bairro = response.data.bairro.toUpperCase();

                          }
              })
              .catch(error => {
                      //Erro ao recuperar os dados da pessoa
                      this.TrataErros(error);
              }); 
            }

          },


          updateValue() {
            this.$emit('input', this.endereco);
          },

          setPais(id_pais){

            if(id_pais != 31){
              this.endereco.cep = '';
              this.endereco.estado = '';
              this.mora_no_brasil = false;
            }else{
              this.mora_no_brasil = true;
            }
           
            this.endereco.id_pais = id_pais;
            
            this.updateValue();
          },
          setCep(cep){
           this.endereco.cep = cep;
           this.updateValue();
          },
          setEstado(estado){
           this.endereco.estado = estado;
           this.updateValue();
          },
          setCidade(cidade){
           this.endereco.cidade = cidade;
           this.updateValue();
          },
          setBairro(bairro){
           this.endereco.bairro = bairro;
           this.updateValue();
          },
          setEndereco(endereco){
           this.endereco.endereco = endereco;
           this.updateValue();
          },
          setNumero(numero){
           this.endereco.numero = numero;
           this.updateValue();
          },
          setComplemento(complemento){
           this.endereco.complemento = complemento;
           this.updateValue();
          },
          setEmailPessoal(email_pessoal){
           this.endereco.email_pessoal = email_pessoal;
           this.updateValue();
          },
          setEmailExtra(email_extra){
           this.endereco.email_extra = email_extra;
           this.updateValue();
          },
          setCelular(celular){
           this.endereco.celular = celular;
           this.updateValue();
          },
          setTelefoneAlternativo(telefone_alternativo){
           this.endereco.telefone_alternativo = telefone_alternativo;
           this.updateValue();
          },


        }
    }
</script>