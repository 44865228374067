<template>
  <div>

  <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    color="green"
  >
    {{ alerta }}
  </v-snackbar> 

  <v-container fluid>


    <v-card  elevation="4" >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="nome_participante" dense hide-details outlined label="Nome" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="cpf_participante" dense hide-details outlined label="CPF" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>   


      <br> 
      

      <v-form ref="form" v-model="form_valido" lazy-validation>  
        <v-row v-if="loading" class="mt-5">
          <v-col>
            <div class="text-center" >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>  
        <v-row v-if="loading" >
          <v-col>
            <div class="text-center" >Carregando</div>
          </v-col>
        </v-row> 

        <v-card outlined elevation="4" v-if="autorizado && !loading">
          <v-card-title>
              <v-row>
                  <v-col class="text-left">
                      <h4 class="text--secondary"> Requerimentos</h4>
                  </v-col>
              </v-row>
          </v-card-title>
          
          <v-card-text>
                  <v-data-table
                    :headers="header"
                    :items="dados"
                    sort-by="data_requerimento"
                    sort-desc
                    class="elevation-2 table"
                    no-data-text="Não há nenhum requerimento"
                    :items-per-page="10"
                    :loading="loading.anexo"   
                    loading-text="Carregando requerimentos..."
                    :footer-props="{
                      itemsPerPageText: 'Linhas por página',
                      itemsPerPageAllText: 'Todas',
                    }"
                    >
                    
                    <template v-slot:item.data_requerimento="{ item }">
                      <span>{{new Date(item.data_requerimento).toLocaleString("pt-BR",{dateStyle: "short"})}}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon class="mr-2" color="orange" elevation="4" v-if="item.situacao === 'E'">mdi-alert-circle-outline</v-icon>
                      <v-icon class="mr-2" color="green" elevation="4" v-else-if="item.situacao === 'D'">mdi-check-circle-outline</v-icon>
                      <v-icon class="mr-2" color="primary" elevation="4" v-else></v-icon>

                      <v-btn v-if="(item.situacao == 'L' || item.situacao == 'E' || item.situacao == 'X') && item.id_tipo!=14"  icon x-small  @click="ChamaFormulario(item)">
                        <v-icon >mdi-pencil</v-icon>
                      </v-btn>
                      <v-tooltip
                        color="rgba(0, 0, 0, 1)"
                        right
                        v-if="item.id_tipo==14"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle
                            
                          </v-icon>
                        </template>
                        Este requerimento só pode ser acessado pela área do participante no menu Cadastro/Recadastramento
                      </v-tooltip>
                      
                    </template>
                  </v-data-table>
          </v-card-text>  
        </v-card>

        <br>

        <v-card v-if="!autorizado && !loading">
          <v-card-text>
            <v-container fluid>
              <v-checkbox
                v-model="resposta"
                label="Concordo em disponibilizar as cópias dos meus documentos para uso do Serpros."
              ></v-checkbox>
            </v-container>
          </v-card-text>  
        </v-card> 

        <br>

        <v-row v-if="!loading">
          <v-col class="text-right">
            <v-btn rounded class="mr-3" color="primary"   @click="autoriza" v-if="resposta " > <v-icon>mdi-content-save</v-icon> Gravar</v-btn>  
            <!--<v-btn rounded class="mr-3" color="#B0BEC5"   to="/adesaops2" v-if="autorizado && !loading"> <v-icon>mdi-folder-plus</v-icon> Adesão ao PSII </v-btn> -->
            <v-btn rounded class="mr-3" color="#B0BEC5"   to="/tiposreq" v-if="autorizado && !loading"> <v-icon>mdi-file-outline</v-icon> Novo Requerimento </v-btn>
            <v-btn rounded              color="#B0BEC5"   @click="perguntaSair" v-if="!loading"> <v-icon>mdi-arrow-left-circle</v-icon> Sair</v-btn> 
          </v-col>
        </v-row>  

        <v-overlay
          absolute
          :value="dialog"
        >
        </v-overlay>

        <v-snackbar
          v-model="dialog"
          multi-line
          centered
          :color="cor_dialogo"
          timeout="-1"
          max-width="400"
          min-height="140"
          vertical
        >
          {{ mensagem }}

          <template v-slot:action="{ attrs }">
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnOk()"
              v-if="!sim_nao"
            >
              Ok
            </v-btn>
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnSim()"
              v-if="sim_nao"
            >
              Sim
            </v-btn>
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnNao()"
              v-if="sim_nao"
            >
              Não
            </v-btn>
          </template>
        </v-snackbar>

      </v-form>  
  </v-container>
  </div>
</template>

<script>
   export default {

      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            multiLine: true,
            absolute: false,
            form_valido: true,
            loading: true,
            exibe_alerta: false,
            alerta: '',
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            titulo: null,
            sim_nao: false,
            reverse: false,
            resposta: false,
            autorizado: false,
            nome_participante: "",
            cpf_participante: "",
            dados: [],
            respostas:[],
            situacoes:[
              {id:'L', descricao: 'EM ELABORAÇÃO'},
              {id:'A', descricao: 'EM ANÁLISE'},
              {id:'C', descricao: 'CONFERIDO'},
              {id:'D', descricao: 'CONCEDIDO'},
              {id:'I', descricao: 'INDEFERIDO'},
              {id:'N', descricao: 'CANCELADO'},
              {id:'E', descricao: 'EM EXIGÊNCIA'},
              {id:'V', descricao: 'AGUARDANDO CONCESSÃO'},
              {id:'F', descricao: 'DEFERIDO'},
              {id:'R', descricao: 'RECÁLCULO'},
              {id:'X', descricao: 'ACEITE'},
              {id:'Z', descricao: 'APROVADO'},
            ],
            header: [
              
                {
                    text: 'Tipo',
                    align: 'start',
                    sortable: false,
                    value: 'tipo_desc',
                    width: 180,
                    class: 'title white--text'
                },
                {
                    text: 'Plano',
                    value: 'plano_sigla',
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Protocolo',
                    value: 'protocolo',
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: 'Data',
                    value: 'data_requerimento',
                    sortable: false,
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: 'Situação',
                    value: 'desc_situacao',
                    width: 120,
                    class: 'title white--text'
                },
                {
                    text: 'Detalhes',
                    value: 'actions',
                    align: 'end',
                    sortable: false,
                    width: 120,
                    class: 'title white--text'
                },
            ],
            
         }
      },
      created: function() {
        var texto = '<p style="color:white; font-size: 12px;">Nesta tela você poderá acompanhar a situação dos seus requerimentos. </p>'+
                    '<p style="color:white;font-size: 12px;">EM ANÁLISE - O requerimento foi enviado ao Serpros e será analisado em breve.</p>'+
                    '<p style="color:white;font-size: 12px;">CONFERIDO - Os documentos necessários foram recepcionados.</p>'+
                    '<p style="color:white;font-size: 12px;">AGUARDANDO CONCESSÃO - Os documentos necessários foram validados, agora o requerimento vai para etapa de concessão.</p>'+
                    '<p style="color:white;font-size: 12px;">EM EXIGÊNCIA - Existe um ou mais documentos no requerimento que foram rejeitados.</p>'+
                    '<p style="color:white;font-size: 12px;">- Caso queira consultar detalhes do requerimento, basta clicar no ícone do lápis</b> </p>';

        this.$store.dispatch('exibeajuda',texto);

        this.nome_participante = this.$store.state.nome;
        this.cpf_participante = this.$store.state.cpf;

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.jaautorizou();
        //this.recuperaRequerimentos();

        //console.log(process.env.VUE_APP_MODE);
      },
      methods: {
        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        },  

        perguntaSair(){

          this.titulo = 'Sair';
          this.mensagem = 'Deseja sair do sistema?';
          this.cor_dialogo = '#283593';
          this.sim_nao = true;
          this.dialog = true;

        },
        
        
        recuperaRequerimentos() {
            this.loading = true;

            

            this.$store.state.API.get( '/api/requerimento/' + this.$store.state.nuvem.id)
            .then(response => {
              this.dados = response.data.requerimentos;
              var situacao = null;
              for (let i = 0; i < this.dados.length; i++) {
                situacao = this.situacoes.find(situacao => situacao.id ===this.dados[i].situacao);
                this.dados[i].desc_situacao = situacao.descricao;
              }

              this.loading = false;
            })/*
            .catch(error => {
              this.TrataErros(error)
            }); */
        },     

        autoriza(){

          this.autorizado = this.resposta; 
          this.resposta = false;
          this.loading = true;

          let data = {
              id_pessoa_fisica: this.$store.state.id_pessoa_fisica,
              token_ged: this.$store.state.token_ged,
              autorizacao: 'S',
              usuario: this.$store.state.cpf
            };
          
          this.$store.state.API.post( '/api/autoriza',  data)
          .then(response => {
              this.$store.commit('setnuvem', response.data.nuvem);
              this.recuperaRequerimentos();
          })
          .catch(error => {
              this.TrataErros(error);

          }); 

        },

        jaautorizou(){

          this.loading = true;

          let data = {cpf: this.$store.state.cpf};

          this.$store.state.API.post( '/api/jaautorizou',  data)
          .then(response => {
            if(response.data.codigo == 'ok'){
              this.autorizado = true;
              this.$store.commit('setnuvem', response.data.nuvem);
              this.recuperaRequerimentos();
            }else{
              this.loading = false;

            }
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },



        //#####################
        // Chama o formulário de requerimento
        // conforme o tipo de requerimento
        //#####################

        ChamaFormulario(item){

          if(item.id_tipo == 1 || item.id_tipo == 2 || item.id_tipo == 3 || item.id_tipo == 7 || item.id_tipo == 8 || item.id_tipo == 13){
              this.$router.push('/requerimento01/'+item.id);
          }else if(item.id_tipo == 11){
              this.$router.push('/requerimento03/'+item.id);
          }else if(item.id_tipo == 16){
              this.$router.push('/requerimento05/'+item.id);
          }else if(item.id_tipo == 10){
              this.$router.push('/requerimento06/'+item.id);
          }else{
              this.$router.push('/requerimento02/'+item.id);
          } 
          
        },


        
        //#####################
        //Funções utilitárias
        //####################


        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 
      },
   }
</script>