<template>
  <div>
    <br>
    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Tipo de pagamento"
          :value="value.tipo_pagamento"
          @input="setTipoPagamento"
          :items="$store.state.tipos_pagamento"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Banco"
          :value="value.banco"
          @input="setBanco"
          :items="bancos"
          item-text="nome"
          item-value="numero"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="2" class="my-0 py-0">
        <v-text-field
          :value="value.agencia"
          @input="setAgencia"
          label="Agencia"
          :rules="[...Rules.Required, ...numeroAgencia, ...minLength]" 
          :readonly="situacao != 'L' && situacao != 'E'"
          maxlength="4"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="1" class="my-0 py-0">
        <v-text-field
          :value="value.agencia_dv"
          @input="setAgenciaDv"
          label="DV"
          :rules="[...Rules.Required, ...numeroAgencia]" 
          :readonly="situacao != 'L' && situacao != 'E'"
          maxlength="2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2" class="my-0 py-0">
        <v-text-field
          :value="value.conta"
          @input="setConta"
          label="Conta"
          :rules="[...Rules.Required, ...numeroConta]" 
          :readonly="situacao != 'L' && situacao != 'E'"
          maxlength="10"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="1" class="my-0 py-0">
        <v-text-field
          :value="value.conta_dv"
          @input="setContaDv"
          label="DV"
          :readonly="situacao != 'L' && situacao != 'E'"
          maxlength="2"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-select
          label="Benefício"
          :value="value.beneficio"
          @input="setBeneficio"
          :items="FiltraBeneficio(id_plano)"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="id_plano == 2 && beneficio == 1">
        <v-select
          label="Parcela à vista 25%"
          :value="value.parcela25"
          @input="setParcela25"
          :items="percentuais25"
          item-text="descricao"
          item-value="id"
          :readonly="situacao != 'L' && situacao != 'E'"

        > 
        </v-select>
      </v-col>

      <v-col cols="12" md="3" class="my-0 py-0" v-if="id_plano == 2 && beneficio == 1">
        <v-select
          label="Parcela à vista 100%"
          :value="value.parcela100"
          @input="setParcela100"
          :items="percentuais100"
          item-text="descricao"
          item-value="id"
          :readonly="situacao != 'L' && situacao != 'E'"

        > 
        </v-select>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0" >
        <v-text-field
          label="RG"
          :value="value.rg_bnf"
          @input="setRgBnf"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" >
        <v-text-field
          label="Órgão emissor"
          :value="value.orgao_emissor_rg_bnf"
          @input="setOrgaoEmissorRgBnf"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" >
        <v-text-field
          label="Data de expedição"
          type="date" 
          :value="value.data_expedicao_rg_bnf"
          @input="setDataExpedicaoRgBnf"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" >
        <v-select
          label="UF"
          :value="value.uf_rg_bnf"
          @input="setUfRgBnf"
          :items="estados"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      
      
    </v-row>

    <v-row >
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-text-field
           label="Data da rescisão do contrato de trabalho"
           type="date" 
           :value="value.data_demissao"
           @input="setDataDemissao"
           :rules="[...rescisao]" 
           :readonly="situacao != 'L' && situacao != 'E'"
         >
         <template v-slot:prepend>
           <v-tooltip
             right
           >
             <template v-slot:activator="{ on }">
               <v-icon v-on="on">
                 mdi-help-circle
               </v-icon>
             </template>
             Deve ser preenchido com a data de rescisão que consta no termo de rescisão do contrato de trabalho.
           </v-tooltip>
         </template>
         </v-text-field>
       </v-col>
    </v-row>  


    <v-row>
      <v-col cols="12" md="6" class="my-0 py-0" >
        <v-text-field
          label="Nome do Pai"
          :value="value.nome_pai"
          @input="setNomePai"
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="my-0 py-0" >
        <v-text-field
          label="Nome da Mãe"
          :value="value.nome_mae"
          @input="setNomeMae"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      
      
    </v-row>


    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Deseja informar representante legal?"
          :value="value.representante_legal"
          @input="setRepresentanteLegal"
          :items="sim_nao"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Nome do Representante"
          :value="value.nome_representante"
          @input="setNomeRepresentante"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="CPF"
          :value="value.cpf_representante"
          @input="setCpf"
          :rules="[...Rules.Required, ...maxLength(11), ...cpf(value.cpf_representante)]"
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
       <v-text-field
          label="Data de nascimento"
          type="date" 
          :value="value.data_nasc_rep"
          @input="setDataNascRep"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
        
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Identidade do Representante"
          :value="value.numero_identidade"
          @input="setIdentidade"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Órgão emissor"
          :value="value.orgao_emissor"
          @input="setOrgaoEmissor"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-text-field
          label="Data de expedição"
          type="date" 
          :value="value.data_expedicao"
          @input="setData"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="1" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-select
          label="UF"
          :value="value.uf"
          @input="setUf"
          :items="estados"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="2" class="my-0 py-0" v-if="representante_legal == 'S'">
        <v-select
          label="Qualidade do representante"
          :value="value.qualidade_representante"
          @input="setQualidade"
          :items="qualidade"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Opção I.R.R.F."
          :value="value.opcao_irrf"
          @input="setOpcaoIrrf"
          :items="FiltraOpcaoIRRF(id_plano)"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
       <v-text-field
          label="Data de início do benefício"
          type="date" 
          :value="value.dib"
          @input="setDib"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
          append-icon="mdi-help-circle"
          v-if="value.beneficio==2 || value.beneficio==4"
        >
        <template v-slot:append>
          <v-tooltip
            right
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-help-circle
              </v-icon>
            </template>
           Deve ser preenchido com a data informada pelo INSS na carta de concessão.
          </v-tooltip>
        </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" md="6" class="text-justify text-h6 ">
        Você está ciente de que a opção I.R.R.F é irreversível?
      </v-col>
      <v-col cols="12" md="2" class="text-justify text-h6  mt-n2">
        <v-switch
          :input-value="value.ciente_opcao_irrf_irreversivel" 
          :label="ciente_opcao_irrf_irreversivel ? 'Sim' : 'Não'"
          :rules="Rules.Required" 
          @change="setCienteOpcaoIrrfIrreversivel"
        ></v-switch>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" md="6" class="text-justify text-h6 ">
        Você está ciente de que a opção pelo benefício é irretratável?
      </v-col>
      <v-col cols="12" md="2" class="text-justify text-h6  mt-n2">
        <v-switch
          :input-value="value.ciente_desligamento_irreversivel" 
          :label="ciente_desligamento_irreversivel ? 'Sim' : 'Não'"
          :rules="Rules.Required" 
          @change="setCienteDesligamentoIrreversivel"
        ></v-switch>
      </v-col>
    </v-row>
  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqBeneficio",
        props: ['value','id_plano','situacao'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            tipo_pagamento: this.value.tipo_pagamento,
            banco: this.value.banco,
            bancos: [
              {numero:"001", nome: "BANCO DO BRASIL"},
              {numero:"033", nome: "SANTANDER"}
            ],
            sim_nao: [
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"}
            ],
            qualidade: [
              {id:1, descricao: "MÃE/PAI"},
              {id:2, descricao: "TUTOR"},
              {id:3, descricao: "CURADOR"},
              {id:4, descricao: "PROCURADOR"}
            ],
            opcoes_irrf: [
              {id:"P", descricao: "PROGRESSIVO"},
              {id:"R", descricao: "REGRESSIVO"},
            ],
            agencia: this.value.agencia,
            agencia_dv: this.value.agencia_dv,
            conta: this.value.conta,
            conta_dv: this.value.conta_dv,
            beneficio: this.value.beneficio,
            parcela25: this.value.parcela25,
            parcela100: this.value.parcela100,
            rg_bnf: this.value.rg_bnf,
            orgao_emissor_rg_bnf: this.value.orgao_emissor_rg_bnf,
            data_expedicao_rg_bnf: this.value.data_expedicao_rg_bnf,
            uf_rg_bnf: this.value.uf_rg_bnf,
            data_demissao: this.value.data_demissao,
            nome_pai: this.value.nome_pai,
            nome_mae: this.value.nome_mae,
            representante_legal: this.value.representante_legal,
            nome_representante: this.value.nome_representante,
            cpf_representante: this.value.cpf_representante,
            numero_identidade: this.value.numero_identidade,
            orgao_emissor: this.value.orgao_emissor,
            data_expedicao: this.value.data_expedicao,
            uf: this.value.uf,
            qualidade_representante: this.value.qualidade_representante,
            opcao_irrf: this.value.opcao_irrf,
            dib: this.value.dib,
            data_nasc_rep: this.value.data_nasc_rep,
            ciente_desligamento_irreversivel: this.value.ciente_desligamento_irreversivel,
            ciente_opcao_irrf_irreversivel: this.value.ciente_opcao_irrf_irreversivel,
            percentuais25:[
              {id:0,descricao:"0 %"},
              {id:1,descricao:"1 %"},
              {id:2,descricao:"2 %"},
              {id:3,descricao:"3 %"},
              {id:4,descricao:"4 %"},
              {id:5,descricao:"5 %"},
              {id:6,descricao:"6 %"},
              {id:7,descricao:"7 %"},
              {id:8,descricao:"8 %"},
              {id:9,descricao:"9 %"},
              {id:10,descricao:"10 %"},
              {id:11,descricao:"11 %"},
              {id:12,descricao:"12 %"},
              {id:13,descricao:"13 %"},
              {id:14,descricao:"14 %"},
              {id:15,descricao:"15 %"},
              {id:16,descricao:"16 %"},
              {id:17,descricao:"17 %"},
              {id:18,descricao:"18 %"},
              {id:19,descricao:"19 %"},
              {id:20,descricao:"20 %"},
              {id:21,descricao:"21 %"},
              {id:22,descricao:"22 %"},
              {id:23,descricao:"23 %"},
              {id:24,descricao:"24 %"},
              {id:25,descricao:"25 %"}
            ],
            percentuais100:[
              {id:0,descricao:"0 %"},
              {id:1,descricao:"1 %"},
              {id:2,descricao:"2 %"},
              {id:3,descricao:"3 %"},
              {id:4,descricao:"4 %"},
              {id:5,descricao:"5 %"},
              {id:6,descricao:"6 %"},
              {id:7,descricao:"7 %"},
              {id:8,descricao:"8 %"},
              {id:9,descricao:"9 %"},
              {id:10,descricao:"10 %"},
              {id:11,descricao:"11 %"},
              {id:12,descricao:"12 %"},
              {id:13,descricao:"13 %"},
              {id:14,descricao:"14 %"},
              {id:15,descricao:"15 %"},
              {id:16,descricao:"16 %"},
              {id:17,descricao:"17 %"},
              {id:18,descricao:"18 %"},
              {id:19,descricao:"19 %"},
              {id:20,descricao:"20 %"},
              {id:21,descricao:"21 %"},
              {id:22,descricao:"22 %"},
              {id:23,descricao:"23 %"},
              {id:24,descricao:"24 %"},
              {id:25,descricao:"25 %"},
              {id:26,descricao:"26 %"},
              {id:27,descricao:"27 %"},
              {id:28,descricao:"28 %"},
              {id:29,descricao:"29 %"},
              {id:30,descricao:"30 %"},
              {id:31,descricao:"31 %"},
              {id:32,descricao:"32 %"},
              {id:33,descricao:"33 %"},
              {id:34,descricao:"34 %"},
              {id:35,descricao:"35 %"},
              {id:36,descricao:"36 %"},
              {id:37,descricao:"37 %"},
              {id:38,descricao:"38 %"},
              {id:39,descricao:"39 %"},
              {id:40,descricao:"40 %"},
              {id:41,descricao:"41 %"},
              {id:42,descricao:"42 %"},
              {id:43,descricao:"43 %"},
              {id:44,descricao:"44 %"},
              {id:45,descricao:"45 %"},
              {id:46,descricao:"46 %"},
              {id:47,descricao:"47 %"},
              {id:48,descricao:"48 %"},
              {id:49,descricao:"49 %"},
              {id:50,descricao:"10 %"},
              {id:51,descricao:"11 %"},
              {id:52,descricao:"12 %"},
              {id:53,descricao:"13 %"},
              {id:54,descricao:"14 %"},
              {id:55,descricao:"15 %"},
              {id:56,descricao:"16 %"},
              {id:57,descricao:"17 %"},
              {id:58,descricao:"18 %"},
              {id:59,descricao:"19 %"},
              {id:60,descricao:"20 %"},
              {id:61,descricao:"21 %"},
              {id:62,descricao:"22 %"},
              {id:63,descricao:"23 %"},
              {id:64,descricao:"24 %"},
              {id:65,descricao:"25 %"},
              {id:66,descricao:"26 %"},
              {id:67,descricao:"27 %"},
              {id:68,descricao:"28 %"},
              {id:69,descricao:"29 %"},
              {id:70,descricao:"30 %"},
              {id:71,descricao:"31 %"},
              {id:72,descricao:"32 %"},
              {id:73,descricao:"33 %"},
              {id:74,descricao:"34 %"},
              {id:75,descricao:"35 %"},
              {id:76,descricao:"36 %"},
              {id:77,descricao:"37 %"},
              {id:78,descricao:"38 %"},
              {id:79,descricao:"39 %"},
              {id:80,descricao:"40 %"},
              {id:81,descricao:"41 %"},
              {id:82,descricao:"42 %"},
              {id:83,descricao:"43 %"},
              {id:84,descricao:"44 %"},
              {id:85,descricao:"45 %"},
              {id:86,descricao:"46 %"},
              {id:87,descricao:"47 %"},
              {id:88,descricao:"48 %"},
              {id:89,descricao:"49 %"},
              {id:90,descricao:"40 %"},
              {id:91,descricao:"41 %"},
              {id:92,descricao:"42 %"},
              {id:93,descricao:"43 %"},
              {id:94,descricao:"44 %"},
              {id:95,descricao:"45 %"},
              {id:96,descricao:"46 %"},
              {id:97,descricao:"47 %"},
              {id:98,descricao:"48 %"},
              {id:99,descricao:"49 %"},
              {id:100,descricao:"100 %"}

            ],
            estados:["AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
              
          }
        }, 
        created:  function() {
          console.log(this.ciente_desligamento_irreversivel);
        },
        methods: {

          rescisao: function(valor) { 
          
            let aprovado = true;
            let mensagem = '';

            if(this.beneficio == 1 || this.beneficio == 3 || this.beneficio == 5 ){
              if(valor == null){
                aprovado = false;
                mensagem = 'Obrigatório' ; 
              }
            }

            return  aprovado || mensagem
          },

          minLength: function(valor) {
          
            let aprovado = true;
            let mensagem = '';

            if(valor.length < 4 ){
              aprovado = false;
              mensagem = 'Mínimo 4 caracteres. Se necessário preencha com um zero a esquerda.' ; 
            }

            return  aprovado || mensagem
          },

          FiltraOpcaoIRRF: function(id_plano){
         
            return this.opcoes_irrf.filter(function(opcao_irrf) {
              var exibe = true;
              if(id_plano == 1 && opcao_irrf.id =="R") {
                exibe = false;
              }
              return exibe;
            })
          },

          FiltraBeneficio: function(id_plano){
        
            return this.$store.state.beneficios.filter(function(beneficio) {
              var exibe = false;
              if(beneficio.id_plano == id_plano ) {
                exibe = true;
              }
              return exibe;
            })
          },

          cpf: function(cpf){
            let valido = this.validarCPF(cpf);
            return valido || 'CPF inválido'
          },

          maxLength: function(max) {
            return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
          },


          updateValue() {

            if(this.beneficio != 1){
              this.parcela25 = 0;
              this.parcela100 = 0;
            }

            this.$emit('input', {
                tipo_pagamento: this.tipo_pagamento,
                banco: this.banco,
                agencia: this.agencia,
                agencia_dv: this.agencia_dv,
                conta: this.conta,
                conta_dv: this.conta_dv,
                beneficio: this.beneficio,
                parcela25: this.parcela25,
                parcela100: this.parcela100,
                rg_bnf: this.rg_bnf,
                orgao_emissor_rg_bnf: this.orgao_emissor_rg_bnf,
                data_expedicao_rg_bnf: this.data_expedicao_rg_bnf,
                uf_rg_bnf: this.uf_rg_bnf,
                data_demissao: this.data_demissao,
                nome_pai: this.nome_pai,
                nome_mae: this.nome_mae,
                representante_legal: this.representante_legal,
                nome_representante: this.nome_representante,
                cpf_representante: this.cpf_representante,
                numero_identidade: this.numero_identidade,
                orgao_emissor: this.orgao_emissor,
                data_expedicao: this.data_expedicao,
                uf: this.uf,
                qualidade_representante: this.qualidade_representante, 
                opcao_irrf: this.opcao_irrf,
                dib: this.dib,
                data_nasc_rep: this.data_nasc_rep,
                ciente_desligamento_irreversivel: this.ciente_desligamento_irreversivel,
                ciente_opcao_irrf_irreversivel: this.ciente_opcao_irrf_irreversivel,
            });
          },


          setBanco(banco){
           this.banco = banco;
           this.updateValue();
          },
          setAgencia(agencia){
           this.agencia = agencia;
           this.updateValue();
          },
          setConta(conta){
           this.conta = conta;
           this.updateValue();
          },
          setAgenciaDv(agencia_dv){
           this.agencia_dv = agencia_dv;
           this.updateValue();
          },
          setContaDv(conta_dv){
           this.conta_dv = conta_dv;
           this.updateValue();
          },
          setTipoPagamento(tipo_pagamento){
           this.tipo_pagamento = tipo_pagamento;
           this.updateValue();
          },
          setBeneficio(beneficio){
           this.beneficio = beneficio;
           this.updateValue();
          },
          setParcela25(parcela){
           this.parcela25 = parcela;
           this.updateValue();
          },
          setParcela100(parcela){
           this.parcela100 = parcela;
           this.updateValue();
          },
          setRepresentanteLegal(representante_legal){
           this.representante_legal = representante_legal;
           this.updateValue();
          },
          setNomeRepresentante(nome_representante){
           this.nome_representante = nome_representante;
           this.updateValue();
          },
          setCpf(cpf_representante){
           this.cpf_representante = cpf_representante;
           this.updateValue();
          },
          setIdentidade(numero_identidade){
           this.numero_identidade = numero_identidade;
           this.updateValue();
          },
          setOrgaoEmissor(orgao_emissor){
           this.orgao_emissor = orgao_emissor;
           this.updateValue();
          },
          setData(data_expedicao){
           this.data_expedicao = data_expedicao;
           this.updateValue();
          },
          setUf(uf){
           this.uf = uf;
           this.updateValue();
          },
          setQualidade(qualidade_representante){
           this.qualidade_representante = qualidade_representante;
           this.updateValue();
          },
          setOpcaoIrrf(opcao_irrf){
           this.opcao_irrf = opcao_irrf;
           this.updateValue();
          },
          setDib(dib){
           this.dib = dib;
           this.updateValue();
          },
          setDataNascRep(data_nasc_rep){
           this.data_nasc_rep = data_nasc_rep;
           this.updateValue();
          },
          setCienteDesligamentoIrreversivel(ciente_desligamento_irreversivel){
           if(ciente_desligamento_irreversivel){
            this.ciente_desligamento_irreversivel = true;
           }else{
            this.ciente_desligamento_irreversivel = false;
           } 
           
           this.updateValue();
          },
          setCienteOpcaoIrrfIrreversivel(ciente_opcao_irrf_irreversivel){
           if(ciente_opcao_irrf_irreversivel){
            this.ciente_opcao_irrf_irreversivel = true;
           }else{
            this.ciente_opcao_irrf_irreversivel = false;
           } 
           
           this.updateValue();
          },
          setRgBnf(rg_bnf){
           this.rg_bnf = rg_bnf;
           this.updateValue();
          },
          setOrgaoEmissorRgBnf(orgao_emissor_rg_bnf){
           this.orgao_emissor_rg_bnf = orgao_emissor_rg_bnf;
           this.updateValue();
          },
          setDataExpedicaoRgBnf(data_expedicao_rg_bnf){
           this.data_expedicao_rg_bnf = data_expedicao_rg_bnf;
           this.updateValue();
          },
          setUfRgBnf(uf_rg_bnf){
           this.uf_rg_bnf = uf_rg_bnf;
           this.updateValue();
          },
          setDataDemissao(data_demissao){
           this.data_demissao = data_demissao;
           this.updateValue();
          },
          setNomePai(nome_pai){
           this.nome_pai = nome_pai;
           this.updateValue();
          },
          setNomeMae(nome_mae){
           this.nome_mae = nome_mae;
           this.updateValue();
          },
          
          
          validarCPF(cpf) {	

            if(cpf == null){
              cpf = "";
            }  

            cpf = cpf.replace(/[^\d]+/g,'');	
            if(cpf == '') return false;	
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 || 
              cpf == "00000000000" || 
              cpf == "11111111111" || 
              cpf == "22222222222" || 
              cpf == "33333333333" || 
              cpf == "44444444444" || 
              cpf == "55555555555" || 
              cpf == "66666666666" || 
              cpf == "77777777777" || 
              cpf == "88888888888" || 
              cpf == "99999999999")
                return false;		
            // Valida 1o digito	
            let add = 0,
                i = 0,
                rev = 0;	

            for (i=0; i < 9; i ++)		
              add += parseInt(cpf.charAt(i)) * (10 - i);	
              rev = 11 - (add % 11);	
              if (rev == 10 || rev == 11)		
                rev = 0;	
              if (rev != parseInt(cpf.charAt(9)))		
                return false;		
            // Valida 2o digito	
            add = 0;	
            for (i = 0; i < 10; i ++)		
              add += parseInt(cpf.charAt(i)) * (11 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)	
              rev = 0;	
            if (rev != parseInt(cpf.charAt(10)))
              return false;		
            return true;   
            
            
          },


          numeroAgencia: function() {
          
            let aprovado = true;
            let mensagem = '';

            if(typeof this.agencia == 'string' || this.agencia instanceof String){
              let tamanho = this.agencia.length + this.agencia_dv.length;

              if( tamanho > 5){
                aprovado = false;
                mensagem = 'Numero + DV máximo 5 caracteres' ; 
              }
            }

            return  aprovado || mensagem
          },

          numeroConta: function() {
            
            let aprovado = true;
            let mensagem = '';

            if(typeof this.conta == 'string' || this.conta instanceof String){
              let tamanho = this.conta.length + this.conta_dv.length;

              if( tamanho > 12){
                aprovado = false;
                mensagem = 'Numero + DV máximo 12 caracteres' ; 
              }
            }

            

            return  aprovado || mensagem
          },
          
        }
    }
</script>