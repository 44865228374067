<template>
  <div>

  <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
  >
    {{ alerta }}
  </v-snackbar> 

  <v-container fluid>
    <v-card  elevation="4" >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="nome_participante" dense hide-details outlined label="Nome" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="cpf_participante" dense hide-details outlined label="CPF" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>  

    <br>
    <v-row v-if="loading" class="mt-5">
      <v-col>
        <div class="text-center" >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>  
    <v-row v-if="loading" >
      <v-col>
        <div class="text-center" >Carregando</div>
      </v-col>
    </v-row> 

    <v-form ref="form" v-model="form_valido" lazy-validation> 
      <v-card outlined elevation="4" v-if="!loading && tela == 1">
        <v-card-title>
            <v-row>
                <v-col class="text-left">
                    <h4 class="text--secondary"> Dados da Entidade Receptora</h4>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="12" md="3" class="my-0 py-0">
              <v-select
                label="Plano"
                v-model="requerimento.id_plano"
                :items="filtraPlanos($store.state.plano_participante)"
                item-text="sigla"
                item-value="id"
                :rules="Rules.Required"  
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                autocomplete="none" 
              > 
              </v-select>
            </v-col>

            <v-col cols="12" md="4" class="my-0 py-0" v-if="requerimento.protocolo">
              <v-text-field
                v-model="requerimento.protocolo"
                label="Protocolo"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.nome_entidade"
                label="Nome da Entidade"
                :rules="Rules.Required"  
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="60"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-text-field
              v-model="detalhes.cnpj_entidade"
              label="CNPJ"
              :rules="[ ...Rules.Required]"  
              :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              maxlength="14"
              autocomplete="none" 
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-select
                label="Tipo de entidade"
                v-model="detalhes.tipo_entidade"
                :items="tipos_entidade"
                item-text="descricao"
                item-value="id"
                :rules="Rules.Required"  
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              > 
              </v-select>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.endereco_entidade"
                label="Endereço da Entidade"
                :rules="Rules.Required"  
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="60"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-text-field
              v-model="detalhes.numero_entidade"
              label="Número"
              :rules="Rules.Required"  
              :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              maxlength="10"
              autocomplete="none" 
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-text-field
              v-model="detalhes.complemento_entidade"
              label="Complemento"
              :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              maxlength="40"
              autocomplete="none" 
              >
              </v-text-field>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12" md="3" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.bairro_entidade"
                label="Bairro"
                :rules="Rules.Required"  
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="20"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-text-field
              v-model="detalhes.cidade_entidade"
              label="Cidade"
              :rules="Rules.Required"  
              :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              maxlength="30"
              autocomplete="none" 
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">

              <v-select
                  
                  label="Estado"
                  v-model="detalhes.uf_entidade"
                  :items="estados"
                  :rules="Rules.Required"  
                  autocomplete="none" 
                > 
                </v-select>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-text-field
              v-model="detalhes.cep_entidade"
                label="CEP"
                :rules="Rules.Required"  
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="8"
                autocomplete="none"
                append-outer-icon="mdi-text-search" 
                @click:append-outer="consultaCEP(detalhes.cep_entidade)"  
              >
              </v-text-field>
            </v-col>

          </v-row>

          


          <v-row>
            <v-col cols="12" md="2" class="my-0 py-0">
              <v-select
                autocomplete="none" 
                label="Banco da entidade"
                v-model="detalhes.banco_entidade"
                :items="bancos"
                item-text="nome"
                item-value="numero"
                :rules="Rules.Required" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              > 
              </v-select>
            </v-col>
            <v-col cols="12" md="2" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.agencia_entidade"
                label="Agência"
                :rules="[...Rules.Required]" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="4"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.agencia_dv_entidade"
                label="DV"
                :rules="[...Rules.Required, ...numeroAgencia]" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="2"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.conta_entidade"
                label="Conta"
                :rules="[...Rules.Required, ...numeroConta]" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="10"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.conta_dv_entidade"
                label="DV"
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                maxlength="2"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-text-field
                v-model="detalhes.nome_plano"
                label="Nome do plano"
                :rules="Rules.Required"  
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
                autocomplete="none" 
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-text-field
              v-model="detalhes.cnpb_susep"
              label="CNPB ou SUSEP"
              :rules="Rules.Required" 
              :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'" 
              autocomplete="none" 
              >
                <template v-slot:prepend>
                  <v-tooltip
                    color="rgba(0, 0, 0, 1)"
                    right
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle
                        
                      </v-icon>
                    </template>
                    Deve ser preenchido com o número identificador <br> do plano junto à PREVIC ou a SUSEP.
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="my-0 py-0">
              <v-select
                label="Opção I.R.R.F."
                v-model="detalhes.opcao_irrf"
                :items="opcoes_irrf"
                item-text="descricao"
                item-value="id"
                :rules="Rules.Required" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              > 
              <template v-slot:prepend>
                <v-tooltip
                  color="rgba(0, 0, 0, 1)"
                  right
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-help-circle
                      
                    </v-icon>
                  </template>
                  Esta opção será usada para o cálculo da quitação de empréstimo caso haja.
                </v-tooltip>
              </template>
              </v-select>
            </v-col> 

          </v-row>

          <br>

        </v-card-text>  
      </v-card>

      <br>

      <v-card outlined elevation="4" v-if="!loading && tela == 1">
        <v-card-title>
            <v-row>
                <v-col class="text-left">
                    <h4 class="text--secondary"> Dados do Participante</h4>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="12" md="3" class="my-0 py-0" >
              <v-text-field
                label="RG"
                v-model="detalhes.rg"
                :rules="Rules.Required" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="my-0 py-0" >
              <v-text-field
                label="Órgão emissor"
                v-model="detalhes.orgao_emissor_rg"
                :rules="Rules.Required" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="my-0 py-0" >
              <v-text-field
                label="Data de expedição"
                type="date" 
                v-model="detalhes.data_expedicao_rg"
                :rules="Rules.Required" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="my-0 py-0" >
              <v-select
                label="UF"
                v-model="detalhes.uf_rg"
                :items="estados"
                :rules="Rules.Required" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              > 
              </v-select>
            </v-col>
            
            
          </v-row>
      
          <v-row >
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-text-field
                 label="Data da rescisão do contrato de trabalho"
                 type="date" 
                 v-model="detalhes.data_demissao"
                 :rules="Rules.Required" 
                 :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
               >
               <template v-slot:prepend>
                 <v-tooltip
                   color="rgba(0, 0, 0, 1)"
                   right
                 >
                   <template v-slot:activator="{ on }">
                     <v-icon v-on="on">
                       mdi-help-circle
                     </v-icon>
                   </template>
                   Deve ser preenchido com a data de rescisão que consta no termo de rescisão do contrato de trabalho.
                 </v-tooltip>
               </template>
               </v-text-field>
             </v-col>
          </v-row>  
      
      
          <v-row>
            <v-col cols="12" md="6" class="my-0 py-0" >
              <v-text-field
                label="Nome do Pai"
                v-model="detalhes.nome_pai"
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="my-0 py-0" >
              <v-text-field
                label="Nome da Mãe"
                v-model="detalhes.nome_mae"
                :rules="Rules.Required" 
                :readonly="requerimento.situacao != 'L' && requerimento.situacao != 'E'"
              >
              </v-text-field>
            </v-col>
            
            
          </v-row>

          

          <br>

        </v-card-text>  
      </v-card>
    

      <br v-if=" tela == 1">
      <req-endereco v-if="!loading && tela == 1 "  v-model="endereco" :situacao="requerimento.situacao" :paises="paises" /> 

    </v-form>


    <!-- DOCUMENTOS DO TITULAR -->
    <v-card outlined elevation="4" v-if="!loading && tela == 2">
      <v-card-title>
          <v-row>
              <v-col class="text-left">
                  <h4 class="text--secondary"> Documentos necessários</h4>
              </v-col>
          </v-row>
      </v-card-title>
      
      <v-card-text>
        <v-data-table
          :headers="header"
          :items="documentos"
          class="elevation-1 table"
          no-data-text="Não há nenhum arquivo"
          :items-per-page="-1"
          :loading="loading.anexo"   
          loading-text="Carregando arquivos..."
          hide-default-footer
          item-key="id_req_doc"
          >


          <template v-slot:[`item.clipe`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small class="mr-2"  @click="selecionarArquivo(item.id_tipo_documento, item.id)" >
                  <v-icon 
                  v-bind="attrs"
                  v-on="on"
                  >mdi-paperclip</v-icon>
                </v-btn>
              </template>
              <span>Anexar</span>
            </v-tooltip>
            <v-icon color="orange"  elevation="4" v-if="item.situacao === 'E'">mdi-alert-circle-outline</v-icon>
            <v-icon color="green"   elevation="4" v-else-if="item.situacao === 'D'">mdi-check-circle-outline</v-icon>
            <v-icon color="primary" elevation="4" v-else></v-icon>
          </template>
        </v-data-table> 
      </v-card-text>  
    </v-card>


    <br>

    <v-row>
      <v-col class="text-right">
        <v-toolbar
          flat
          color="grey lighten-3"
        >
          <v-btn rounded class="mr-3" color="#B0BEC5"   @click="salvar('N')" v-if="!loading && !dialog && (requerimento.situacao =='L' || requerimento.situacao =='E')">Cancelar Requerimento</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded class="mr-3" color="#B0BEC5"   @click="voltar" v-if="!loading && !dialog"> <v-icon>mdi-arrow-left-circle</v-icon> Voltar </v-btn>
          <v-btn rounded  color="primary"   @click="salvar('L')"  v-if="!loading && (requerimento.situacao =='L' || requerimento.situacao =='E')  && tela < 2"> <v-icon>mdi-content-save</v-icon> Prosseguir </v-btn>
          <v-btn rounded  color="green"   @click="salvar('A')"  :disabled="!pode_enviar" v-if="!loading && tela == 2"> Finalizar requerimento </v-btn>
          
        </v-toolbar>
        
      </v-col>
    </v-row> 


    <v-card outlined elevation="4" v-if="!loading  && mensagens.length > 0">
      <v-card-title>
          <v-row>
              <v-col class="text-left">
                  <h4 class="text--secondary"> Mensagens</h4>
              </v-col>
          </v-row>
      </v-card-title>

        <v-timeline
          dense
        >
          <v-timeline-item
            v-for="mensagem in mensagens"
            :key="mensagem.id" 
          >
            <v-row class="pt-1">
            <v-col>
              <strong>{{new Date(mensagem.data_gravacao).toLocaleString("pt-BR",{dateStyle: "short"})}}</strong> - {{ mensagem.mensagem }}
            </v-col>

            </v-row>

          </v-timeline-item>
        </v-timeline>

      <v-card-text>
        
      </v-card-text>  
    </v-card>


    <v-file-input
      truncate-length="150"
      accept='.pdf,.png,.jpg,.jpeg'
      v-model="arquivo"
      id="fileUpload1"
      :rules="Rules.Arquivo"
      hide-input
      prepend-icon=""
      @change="enviarArquivo()"
    ></v-file-input>
    
    

    <v-overlay
      :absolute="absolute"
      :value="dialog"
    >
    </v-overlay>

    <v-snackbar
      v-model="dialog"
      :multi-line="multiLine"
      centered
      :color="cor_dialogo"
      timeout="-1"
      max-width="400"
      min-height="140"
      vertical
    >
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnOk()"
          v-if="!sim_nao"
        >
          OK
        </v-btn>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnSim(titulo)"
          v-if="sim_nao"
        >
          Sim
        </v-btn>
                <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnNao()"
          v-if="sim_nao"
        >
          Não
        </v-btn>
      </template>
    </v-snackbar>
 
  </v-container>
  </div>
</template>

<script>
   import ReqEndereco from '@/components/ReqEndereco.vue'

   export default {
    components: {
        ReqEndereco,           
      },
      data: function(){
         return {
            Rules:{
              Tipo: [v => !!v || 'Escolha o tipo de documento que você vai enviar',],
              Arquivo: [v => !!v || 'Escolha um arquivo para enviar',],
              Required: [v => !!v || 'Obrigatório',],
            },
            tela: 1,
            multiLine: true,
            overlay: false,
            absolute: false,
            form_valido: true,
            loading: false,
            mensagem: '',
            dialog: false,
            titulo: null,
            cor_dialogo: '#455A64',
            sim_nao: false,
            nome_participante: '',
            cpf_participante: '',
            aceito: '',
            requerimento: {
              id: null,
              id_plano: null,
              id_tipo_requerimento: null,
              situacao: 'L',
              protocolo: null
            },
            endereco: {},
            header: [
              
                {
                    text: 'Documento',
                    align: 'start',
                    sortable: false,
                    value: 'tipo_documento.descricao',
                    width: 220,
                    class: 'title white--text'
                },
                {
                    text: 'Arquivo',
                    value: 'documento.arquivo',
                    sortable: false,
                    width: 180,
                    class: 'title white--text'
                },
                {
                    text: 'Situação',
                    value: 'situacao.descricao', 
                    sortable: false,
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: '',
                    align: 'end',
                    value: 'clipe',
                    sortable: false,
                    width: 40,
                    class: 'title white--text'
                },
            ],
            
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            id_tipo_documento: null, 
            id_req_doc: null,
            pode_enviar: false,
            situacoes:[
              {id:'L', descricao: 'EM ELABORAÇÃO'},
              {id:'A', descricao: 'EM ANÁLISE'},
              {id:'C', descricao: 'CONFERIDO'},
              {id:'D', descricao: 'DEFERIDO'},
              {id:'I', descricao: 'INDEFERIDO'},
              {id:'N', descricao: 'CANCELADO'},
              {id:'E', descricao: 'EM EXIGÊNCIA'},
              {id:'V', descricao: 'AGUARDANDO DEFERIMENTO'},
              {id:'F', descricao: 'DEFERIDO'},
              {id:'R', descricao: 'RECÁLCULO'},
              {id:'X', descricao: 'ACEITE'},
              {id:'Z', descricao: 'APROVADO'},
            ],
            mensagens: [],
            arquivo: null,
            id_pessoa: null,
           
            sim_nao_vazio:[
              {id:"", descricao: ""},
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"},
            ],
            sexo:[
              {id:"M", descricao:"MASCULINO"},
              {id:"F", descricao:"FEMININO"},
            ],
            tipos_entidade:[
              {id:"A", descricao:"ABERTA"},
              {id:"F", descricao:"FECHADA"},
            ],
            opcoes_irrf: [
              {id:"P", descricao: "PROGRESSIVO"},
              {id:"R", descricao: "REGRESSIVO"},
            ],
            paises: [],
            estados:["AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
            requerimento:{},
            detalhes:{},
            bancos: [
              {numero:"001", nome: "BANCO DO BRASIL"},
              {numero:"104", nome: "CAIXA ECONÔMICA FEDERAL"},
              {numero:"237", nome: "BRADESCO"},
              {numero:"341", nome: "ITAÚ"},
              {numero:"033", nome: "SANTANDER"},
              {numero:"037", nome: "BANPARÁ"},
              {numero:"041", nome: "BANRISUL"},
              {numero:"070", nome: "BANCO DE BRASÍLIA"},
              {numero:"077", nome: "BANCO INTER"},
              {numero:"290", nome: "PAGBANK - PAGSEGURO INTERNET"},
              {numero:"323", nome: "MERCADO LIVRE"},
              {numero:"336", nome: "BANCO C6"},
              {numero:"348", nome: "BANCO XP SA"},
              {numero:"389", nome: "BANCO MERCANTIL DO BRASIL"},
              {numero:"399", nome: "HSBC"},
              {numero:"422", nome: "SAFRA"},
              {numero:"735", nome: "NEON"},
              {numero:"745", nome: "CITIBANK"},
              {numero:"208", nome: "PACTUAL"},
              {numero:"212", nome: "BANCO ORIGINAL"},
              {numero:"260", nome: "NUBANK"},
              {numero:"748", nome: "BANCO SICREDI"},
              {numero:"756", nome: "BANCOOB"},
 
            ],
         }
      },


      created: function() {

        this.nome_participante = this.$store.state.nome;
        this.cpf_participante = this.$store.state.cpf;
       

        if(this.$store.state.alerta != ''){
            this.alerta = this.$store.state.alerta;
            this.$store.commit('setalerta','');
            this.alerta_color = "green";
            this.exibe_alerta = true;
        }

        this.recuperaTabelas(); 
        
        if(this.$route.params.id != null){ 
          
          this.CarregaRequerimento(this.$route.params.id);
 
        }else{
          this.novo();
        }


        var texto = '<p style="color:white;font-size: 14px;">- Preencha os dados do requerimento e clique no botão <b>Próximo</b>.'+
                    '<p style="color:white;font-size: 14px;">- Na lista de documentos necessários, clique no ícone do clipe para anexar um arquivo ao seu requerimento. </p>'+
                    '<p style="color:white;font-size: 14px;">- Quando tiver terminado de anexar todos os arquivos clique no botão <b>Finalizar requerimento</b>.</p>';
                    

        this.$store.dispatch('exibeajuda',texto);


      },

      methods: { 

        //#####################
        //Regras de validação do formulário
        //####################
        maxLength: function(max) {
          return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
        },


        numeroAgencia: function() {
          
          let aprovado = true;
          let mensagem = '';

          let tamanho = this.detalhes.agencia_entidade.length + this.detalhes.agencia_dv_entidade.length;

          if( tamanho > 5){
            aprovado = false;
            mensagem = 'Numero + DV máximo 5 caracteres' ; 
          }

          return  aprovado || mensagem
        },

        numeroConta: function() {
          
          let aprovado = true;
          let mensagem = '';

          let tamanho = this.detalhes.conta_entidade.length + this.detalhes.conta_dv_entidade.length;

          if( tamanho > 12){
            aprovado = false;
            mensagem = 'Numero + DV máximo 12 caracteres' ; 
          }

          return  aprovado || mensagem
        },

        
                
        //#####################
        //Filtros
        //####################
        filtraPlanos: function(plano_participante) {

          if(plano_participante.length > 0){
            
            var requerimento = this.requerimento;
            return this.$store.state.planos.filter(function(plano) {
              var exibe = false;
              for(var i=0; i < plano_participante.length; i++ ){
                if(plano.id == parseInt(plano_participante[i].id_plano) ) {
                    
                  exibe = true;
                    
                }
              }
              
              return exibe;
            })

          }else{
             return this.$store.state.planos;
          }

        },


        
               

        //#####################
        //click dos botões da tela
        //####################

        voltar(){
          
          if(this.tela > 1){
             this.tela -- ;
          }else{
            this.$router.push('/');
          }
        },
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        },   
        
        BtnSim(titulo){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          

          if(titulo == 'Alerta'){
           this.sair();
          }
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 



        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        }, 


        CarregaRequerimento(id){
            this.loading = true;
            this.$store.state.API.get( '/api/reqportsaida/busca/'+id )
            .then(response => {

              this.requerimento = response.data.requerimento;
              this.endereco = response.data.endereco;
              this.detalhes = response.data.detalhes;
              this.documentos = response.data.documentos; 

              
              this.loading = false;
   
            })
            .catch(error => {
                this.TrataErros(error)
            });
        },


        novo(){

          this.loading = true;

          let data = {
              id_nuvem: this.$store.state.nuvem.id,
          };

          this.$store.state.API.post( '/api/reqportsaida/novo', data)
          .then(response => {

            this.requerimento = response.data.requerimento;
            this.endereco = response.data.endereco;
            this.detalhes = response.data.detalhes;
            this.documentos = response.data.documentos;

            this.loading = false;
          })
          .catch(error => {
              this.TrataErros(error)
          });


        },


        salvar(situacao){

          this.loading = true;

          this.form_valido = false;

          if(this.$refs.form.validate() ){
            
            this.requerimento.situacao = situacao;

            let data = {
                usuario: this.$store.state.cpf,
                requerimento: this.requerimento,
                endereco: this.endereco, 
                detalhes: this.detalhes,
            };

            this.$store.state.API.post( '/api/reqportsaida/salvar', data)
            .then(response => {

              this.requerimento = response.data.requerimento;
              this.endereco = response.data.endereco; 
              this.detalhes = response.data.detalhes;
              this.documentos = response.data.documentos;
              

              if(situacao == 'L'){
                this.tela = 2;
                this.VerificaSePodeEnviar();
              }

              if(situacao == 'N'){
                this.$router.push('/');
              }

              if(situacao == 'A'){
                this.$store.commit('setalerta','Requerimento enviado para análise!');
                this.$router.push('/');
              }

              this.loading = false;
              
            })
            .catch(error => {
                this.TrataErros(error)
            });

          }else{

            this.alerta_color = '#B00020';
            this.alerta = 'Problemas de preenchimento. Verifique o formulário.';
            this.exibe_alerta = true;
            this.loading = false;

          }  

          
          

        },


        GravaMensagem(){

          this.loading = true;

          if(this.msg_detalhes != ''){
            this.msg_detalhes = ' '+this.msg_detalhes;
          }

          var data = {
            id_requerimento: this.requerimento.id,
            msg: this.msg_exigencia + this.msg_detalhes
          };

          this.$store.state.API.post('/api/requerimento/mensagem/gravar', data)
          .then(response => {
            
            if(response.data.codigo == 'ok'){
              this.$router.push('/');
            }else{
              console.log(response.data.error_description);
              this.loading = false;
              this.sim_nao = false;
              this.titulo = 'Alerta';
              this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
              this.dialog = true;
            }  
          })
          .catch(error => {
              this.TrataErros(error);
          }); 

        },




        selecionarArquivo($id_tipo_documento, $id_req_doc) {
          //console.log(this.id_tipo_documento);
          this.id_tipo_documento = $id_tipo_documento;
          this.id_req_doc = $id_req_doc;
          
          document.getElementById("fileUpload1").click();
          
        },




        enviarArquivo() {

          if(event.target.files[0].size <= this.$store.state.max_file_size * 1048576 ){

            this.loading = true;

            let formData = new FormData();
            formData.append('id_nuvem', this.$store.state.nuvem.id);
            formData.append('id_tipo', 405);
            formData.append('id_tipo_documento',this.id_tipo_documento); 
            formData.append('id_protocolo_ged',this.$store.state.nuvem.id_protocolo_ged);
            formData.append('token_ged',this.$store.state.token_ged);
            formData.append('arquivo', this.arquivo);
            formData.append('usuario', this.$store.state.cpf);
            formData.append('nome', this.$store.state.nome);
            formData.append('id_origem', 1);
            formData.append('escopo', 'P');

            this.$store.state.API.post( '/api/novodocumento',  formData)
            .then(response => {

              if(response.data.codigo == 'ok'){
                this.atualizaDocumento(this.id_req_doc, response.data.id_documento, this.id_tipo_documento);
              }
            }) 
            .catch(error => {
                this.TrataErros(error);

            });  
          }else{
            this.alerta = 'Documento muito grande! Tamanho máximo: '+this.$store.state.max_file_size+' MB';
            this.alerta_color = '#B00020';
            this.exibe_alerta = true;
          }

        },

        atualizaDocumento(id_req_doc, id_documento, id_tipo_documento){


          let data = {
                id_req_doc: id_req_doc,
                id_documento: id_documento,
                id_situacao: 4,
                usuario: this.$store.state.cpf,
                id_nuvem: this.$store.state.nuvem.id,
                id_requerimento: this.requerimento.id,
                id_tipo_documento: id_tipo_documento
              };


          this.$store.state.API.post( '/api/requerimento/atualizadocumento',  data)
          .then(response => {

              if(response.data.codigo == 'ok'){

                this.alerta = 'Documento gravado com sucesso!';
                this.alerta_color = "green";

                const index = this.documentos.findIndex((documento, index) => {
                  if (documento.id_tipo_documento == id_tipo_documento) {
                    return true
                  }
                });


                this.documentos[index].id = response.data.id_req_doc;
                this.documentos[index].id_documento = id_documento;
                this.documentos[index].id_situacao = 4;
                this.documentos[index].documento.arquivo = this.arquivo.name;
                this.documentos[index].situacao.descricao = "EM ANÁLISE"; 

                


                this.VerificaSePodeEnviar();

                this.id_tipo_documento = null;
                this.id_req_doc = null;  
                this.exibe_alerta = true; 
                this.arquivo = null; 
                this.loading  = false;    
        
              }else{
                this.loading = false;
              }
              
          })
          .catch(error => {
              this.TrataErros(error);
          }); 

        },


        VerificaSePodeEnviar(){


        
          this.pode_enviar = false;

          if(this.requerimento.situacao == 'L' || this.requerimento.situacao == 'E'){

            var pode = true;
            
            for (var i = 0; i < this.documentos.length; i++) {
                if(this.documentos[i].id_situacao != 1 && this.documentos[i].id_situacao != 4 && this.documentos[i].id_situacao != 5){
                    pode = false;                    
                }
            }

          }

          this.pode_enviar = pode;
         
        },



        recuperaTabelas() {

          //busca a pessoa

          this.$store.state.API.get( '/api/tabelas')
          .then(response => {

              this.planos = response.data.plano;                    
              this.nacionalidade = response.data.nacionalidade;
              this.estado_civil = response.data.estadoCivil; 
              this.escolaridade = response.data.escolaridade;
              this.tipoTempoServico = response.data.tipoTempoServico;
              this.parentesco = response.data.parentesco;
              this.paises = response.data.paises; 
              

          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 

        },





        //#####################
        //funções utilitárias
        //####################

        idade(data_nascimento, data_atual) {
                
                var ano_atual = parseInt(data_atual.substr(0,4)),
                mes_atual = parseInt(data_atual.substr(5,2)),
                dia_atual = parseInt(data_atual.substr(8,2)),
                ano_aniversario = parseInt(data_nascimento.substr(0,4)),
                mes_aniversario = parseInt(data_nascimento.substr(5,2)),
                dia_aniversario = parseInt(data_nascimento.substr(8,2)),


        

                ano_aniversario = +ano_aniversario,
                mes_aniversario = +mes_aniversario,
                dia_aniversario = +dia_aniversario,

                quantos_anos = ano_atual - ano_aniversario;

    


            if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
                quantos_anos--;
            }

            return quantos_anos < 0 ? 0 : quantos_anos;  

        },



        consultaCEP(cep) {
            //busca o cep
            if(cep.length == 8){
              this.axios({
                method: 'get',
                url: 'https://viacep.com.br/ws/'+cep+'/json/',
              })
              .then(response => {
                  if("cep" in response.data){
                      this.detalhes.endereco_entidade = response.data.logradouro.toUpperCase();
                      this.detalhes.uf_entidade = response.data.uf;
                      this.detalhes.cidade_entidade = response.data.localidade.toUpperCase();
                      this.detalhes.bairro_entidade = response.data.bairro.toUpperCase();
                  }
              })
              .catch(error => {
                      //Erro ao recuperar os dados da pessoa
                      this.TrataErros(error);
              }); 
            }

          },


        

        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        }
        

      }

      
   }
</script>