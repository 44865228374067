<template>
  <div>

  <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
  >
    {{ alerta }}
  </v-snackbar> 

  <v-container fluid>
    <v-card  elevation="4" >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="nome_participante" dense hide-details outlined label="Nome" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="cpf_participante" dense hide-details outlined label="CPF" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>  

    <br>
    <v-row v-if="loading" class="mt-5">
      <v-col>
        <div class="text-center" >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>  
    <v-row v-if="loading" >
      <v-col>
        <div class="text-center" >Carregando</div>
      </v-col>
    </v-row> 

    
    <v-card class="mt-5" flat v-if="!loading">
      <v-toolbar color="primary" flat dense dark>
            <v-toolbar-title>
              <v-row align="center">
                  <v-col class="shrink">Novas Informações dos Beneficiários e Designados</v-col>
              </v-row>
            </v-toolbar-title>
      </v-toolbar>
      <v-card-text >
          <v-container
            v-for="dependente in dependentes"
            :key="dependente.id"
            fluid
          >
            <v-card color="#ECEFF1">
              <v-card-text>
                <v-row class="mb-n5">
                  <v-col cols="12" md="4">
                    <v-text-field readonly  dense v-model="dependente.nome" label="* Nome"  ></v-text-field>
                  </v-col> 
                  <v-col cols="12" md="2">
                    <v-text-field readonly  dense v-model="dependente.cpf" label="* CPF"  ></v-text-field>
                  </v-col> 
                  <v-col cols="12" md="2">
                    <v-select
                      readonly 
                      label="* Parentesco"
                      v-model="dependente.id_parentesco"
                      :items="parentesco"
                      item-text="descricao"
                      item-value="id"
                      dense
                      
                      @input="Bigamia()" 
                    > 
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="2">

                    <v-text-field readonly  type="date" dense v-model="dependente.data_nascimento" label="* Data de nascimento"    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select
                      readonly 
                      label="* Inválido"
                      v-model="dependente.invalido"
                      :items="sim_nao_vazio"
                      item-text="descricao"
                      item-value="id"
                      
                      dense
                      
                    > 
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="mb-n5">
                  <v-col cols="12" md="2">
                    <v-select
                      readonly 
                      label="* Sexo"
                      v-model="dependente.sexo"
                      :items="sexo"
                      item-text="descricao"
                      item-value="id"
                      dense
                      
                    > 
                    </v-select>
                  </v-col> 



                </v-row>
              </v-card-text>
              
              
            </v-card>  
          </v-container>
      </v-card-text>
      
    </v-card>

    

    
    <!-- DOCUMENTOS BENEFICIARIOS  -->

    <div v-if="!loading && requerimento.situacao=='E'">
      <div v-for="dependente in dependentes" :key="dependente.id">
      <v-card flat >
        <v-card-title>
          <v-row>
            <v-col class="text-left">
              <h4 class="text--secondary">Documentos necessários - {{ dependente.nome }} </h4>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="header"
            :items="dependente.documentos"
            class="elevation-2 table"
            no-data-text="Não há nenhum arquivo"
            :items-per-page="-1"
            :loading="loading.anexo"   
            loading-text="Carregando arquivos..."
            hide-default-footer
            item-key="id_req_bnf_doc"
            >

            <template v-slot:[`item.download`]="{ item }">

                <v-btn icon class="mr-2 text-center" :href="$store.state.urls.ged+'/documento/'+item.id_documento_ged+'/'+id_protocolo_ged+'/download'">
                    <v-icon>mdi-download-circle</v-icon>
                </v-btn>  
                <v-btn icon class="mr-2 text-center" @click="downloadAnexo(item)">
                    <v-icon>mdi-eye</v-icon>
                </v-btn> 
            </template>


            <template v-slot:[`item.id_situacao`]="{ item }" >

                <v-select
                    single-line
                    dense
                    hide-details
                    v-model="item.id_situacao"
                    :items="filtraSituacoes($store.state.papel.id)"
                    item-text="descricao"
                    item-value="id" 
                    @change="gravaSituacaoDocBnf(item)"
                    v-if="(requerimento.situacao == 'A' || requerimento.situacao == 'C') && item.arquivo != null && $store.state.papel.id != 56558"
                    >
                </v-select> 
                <span v-if="(requerimento.situacao !== 'A' && requerimento.situacao != 'C' && item.arquivo != null) || $store.state.papel.id == 56558"> {{ FiltraSituacao(item.id_situacao) }} </span>

            </template>

          </v-data-table> 
        </v-card-text>  
      </v-card>

      </div>
    </div>

    <!-- FIM DOCUMENTOS BENEFICIARIOS -->

    <v-card flat v-if="!loading ">
      <v-card-title>
        <v-row>
          <v-col class="text-left">
              <h4 class="text--secondary"> Requerimento de {{ descricao_tipo_requerimento }}</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="text-left">
            <p class="text-body-1 font-weight-medium text-justify">
              Durante o processo de recadastramento você solicitou a alteração dos dados dos seus beneficiários. 
              O valor do benefício do Serpros é calculado com base nos beneficiários informados no momento da aposentadoria. Quando um participante solicita alteração nos dados dos seus Beneficiários
              uma alteração no valor do seu benefício pode ocorrer.
            </p>  
            <p class="text-body-1 font-weight-medium text-justify">  
              Caso você esteja de acordo com o novo valor do benefício, marque a opção "Estou de acordo com o novo valor do meu benefício" caso contrário
              marque a opção "Não estou de acordo. Desejo cancelar a alteração dos meus beneficiários"
            </p>
            <p class="text-body-1 font-weight-medium text-justify">  
              Caso você marque a segunda opção não haverá nenhuma alteração no valor do seu benefício nem nos seus beneficiários.
            </p>
            <h2 class="text--secondary"> Novo valor do benefício: {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(novo_valor_bnf)}}</h2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card flat v-if="!loading ">
      <v-card-text>
        <v-row>
          <v-col cols="12" >
            <v-radio-group v-model="aceito"  :rules="[...Aceito()]" >
              <v-radio
                key=0
                label="Estou de acordo com o novo valor do meu benefício"
                value="SIM"
              ></v-radio>
              <v-radio
                key=1
                label="Não estou de acordo. Desejo cancelar a alteração dos meus beneficiários"
                value="NAO"
              ></v-radio>
            </v-radio-group>
          </v-col>  
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-if="!loading">
      <v-col class="text-right">
        <v-toolbar
          flat
          color="grey lighten-3"
        >
          
          <v-btn rounded  color="green"   @click="salvar"  > Enviar </v-btn>
          
        </v-toolbar>
        
      </v-col>
    </v-row> 

    <br>
    <v-card outlined elevation="4" v-if="!loading  && mensagens.length > 0">
      <v-card-title>
          <v-row>
              <v-col class="text-left">
                  <h4 class="text--secondary"> Mensagens</h4>
              </v-col>
          </v-row>
      </v-card-title>

        <v-timeline
          dense
        >
          <v-timeline-item
            v-for="mensagem in mensagens"
            :key="mensagem.id" 
          >
            <v-row class="pt-1">
            <v-col>
              <strong>{{new Date(mensagem.data_gravacao).toLocaleString("pt-BR",{dateStyle: "short"})}}</strong> - {{ mensagem.mensagem }}
            </v-col>

            </v-row>

          </v-timeline-item>
        </v-timeline>

      <v-card-text>
        
      </v-card-text>  
    </v-card>

    <v-overlay
      :absolute="absolute"
      :value="dialog"
    >
    </v-overlay>

    <v-snackbar
      v-model="dialog"
      :multi-line="multiLine"
      centered
      :color="cor_dialogo"
      timeout="-1"
      max-width="400"
      min-height="140"
      vertical
    >
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnOk()"
          v-if="!sim_nao"
        >
          OK
        </v-btn>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnSim(titulo)"
          v-if="sim_nao"
        >
          Sim
        </v-btn>
                <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnNao()"
          v-if="sim_nao"
        >
          Não
        </v-btn>
      </template>
    </v-snackbar>
 
  </v-container>
  </div>
</template>

<script>


   export default {

      data: function(){
         return {
            Rules:{
              Tipo: [v => !!v || 'Escolha o tipo de documento que você vai enviar',],
              Arquivo: [v => !!v || 'Escolha um arquivo para enviar',],
              Required: [v => !!v || 'Obrigatório',],
            },
            tela: 1,
            multiLine: true,
            overlay: false,
            absolute: false,
            form_valido: true,
            form2_valido: true,
            loading: false,
            excluindo: false,
            mensagem: '',
            dialog: false,
            titulo: null,
            cor_dialogo: '#455A64',
            sim_nao: false,
            reverse: false,
            nome_participante: '',
            cpf_participante: '',
            id_categoria: null,
            id_situacao: null,
            paises: [],
            aceito: '',
            requerimento: {
              id: null,
              id_plano: null,
              id_tipo_requerimento: null,
              situacao: 'L',
              protocolo: null
            },
            dependentes: [],
            header: [
              
                {
                    text: 'Documento',
                    align: 'start',
                    sortable: false,
                    value: 'desc_tipo_documento',
                    width: 220,
                    class: 'title white--text'
                },
                {
                    text: 'Arquivo',
                    value: 'arquivo',
                    sortable: false,
                    width: 180,
                    class: 'title white--text'
                },
                {
                    text: 'Situação',
                    value: 'desc_situacao', 
                    sortable: false,
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: '',
                    align: 'end',
                    value: 'clipe',
                    sortable: false,
                    width: 40,
                    class: 'title white--text'
                },
            ],
            
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            exibe_escolha: false,
            exibe_escolha_complementar: false,
            escolhido: 1,
            id_tipo_documento: null, 
            id_req_doc: null,
            id_req_bnf_doc: null,
            pode_enviar: false,
            novo_valor_bnf: null,
            situacoes:[
              {id:'L', descricao: 'EM ELABORAÇÃO'},
              {id:'A', descricao: 'EM ANÁLISE'},
              {id:'C', descricao: 'CONFERIDO'},
              {id:'D', descricao: 'DEFERIDO'},
              {id:'I', descricao: 'INDEFERIDO'},
              {id:'N', descricao: 'CANCELADO'},
              {id:'E', descricao: 'EM EXIGÊNCIA'},
              {id:'V', descricao: 'AGUARDANDO DEFERIMENTO'},
              {id:'F', descricao: 'DEFERIDO'},
              {id:'R', descricao: 'RECÁLCULO'},
              {id:'X', descricao: 'ACEITE'},
              {id:'Z', descricao: 'APROVADO'},
            ],
            mensagens: [],
            data_atual: '',
            arquivo: null,
            migrado: '',
            documentosComplementares: [],
            descricao_tipo_requerimento: '',
            texto_botao_1: 'Próximo',
            docs_obrig_bnf: [],
            id_dependente: null,
            opcao_upload: "",
            id_pessoa: null,
            pensionistas_trust: [],
            carregando_pensionistas: false,
            salariops2: 0,
            vrs: 0,
            sim_nao_vazio:[
              {id:"", descricao: ""},
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"},
            ],
            sexo:[
              {id:"M", descricao:"MASCULINO"},
              {id:"F", descricao:"FEMININO"},
            ],
         }
      },


      created: function() {

        this.nome_participante = this.$store.state.nome;
        this.cpf_participante = this.$store.state.cpf;
       

        if(this.$store.state.alerta != ''){
            this.alerta = this.$store.state.alerta;
            this.$store.commit('setalerta','');
            this.alerta_color = "green";
            this.exibe_alerta = true;
        }

        this.recuperaTabelas();

        

        if(typeof this.$route.params.id_tipo_req != 'undefined'){ 
            this.requerimento.id_tipo_requerimento = parseInt(this.$route.params.id_tipo_req);
        }

        if(this.$route.params.id != 'undefined'){ 

            this.CarregaRequerimento(this.$route.params.id);
 
        }else{

            var tipo_req = this.$store.state.tipos_req.find(element => element.id == this.requerimento.id_tipo_requerimento);
            this.descricao_tipo_requerimento = tipo_req.descricao;

        }


        var texto = '<p style="color:white;font-size: 14px;">- Preencha os dados do requerimento e clique no botão <b>Próximo</b>.'+
                    '<p style="color:white;font-size: 14px;">- Na lista de documentos necessários, clique no ícone do clipe para anexar um arquivo ao seu requerimento. </p>'+
                    '<p style="color:white;font-size: 14px;">- Quando tiver terminado de anexar todos os arquivos clique no botão <b>Finalizar requerimento</b>.</p>';
        
        if(this.requerimento.id_tipo_requerimento == 1){
            texto = texto + '<p style="color:white;font-size: 14px;">- Parcela à vista 25%: Vinte e cinco por cento da parcela da Conta de Participante constituída a partir das Contribuições Básica, Variável e dos recursos referidos no inciso IV do artigo 49 do regulamento do PSII. </p>'+
            '<p style="color:white;font-size: 14px;">- Parcela à vista 100%: Cem por cento da parcela da Conta de Participante constituída a partir de Contribuições Espontâneas e Portabilidade. </p>'+
            '<p style="color:white;font-size: 14px;">- Data de Início do Benefício: No requerimento de aposentadoria por invalidez a data de início do benefício deve ser preenchida com a data informada pelo INSS na carta de concessão. </p>';
        }
                    

        this.$store.dispatch('exibeajuda',texto);


      },

      methods: { 

        //#####################
        //Regras de validação do formulário
        //####################
        maxLength: function(max) {
          return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
        },

        Aceito: function() {

          let aprovado = true;
          let mensagem = '';


          if(this.aceito == "" || this.aceito == null || this.aceito == 0){
            aprovado = false;
            mensagem = 'Obrigatório';
          }

          return  [aprovado || mensagem];

        },

        
        //#####################
        //Filtros
        //####################

        filtraPlanos: function(plano_participante) {

          if(plano_participante.length > 0){
            
            var requerimento = this.requerimento;
            return this.$store.state.planos.filter(function(plano) {
              var exibe = false;
              for(var i=0; i < plano_participante.length; i++ ){
                  if(plano.id == parseInt(plano_participante[i].id_plano) ) {
                    
                    if(requerimento.id_tipo_requerimento == 1 ){
                      if(plano_participante[i].id_categoria != 9){
                        exibe = true;
                      }
                    }else{
                      if(requerimento.id_tipo_requerimento == 3 ){
                        
                        if(plano_participante[i].id_situacao == 7 || plano_participante[i].id_situacao == 8 || plano_participante[i].id_situacao == 14){
                          exibe = false;
                        }else{
                          exibe = true;
                        }
                      }else{
                        exibe = true;
                      }
                      
                    }
                      
                      
                  }
              }
              
              return exibe;
            })

          }else{
             return this.$store.state.planos;
          }

        },

        FiltraTipoRequerimento: function(id_plano){


          if(this.$store.state.plano_participante.length > 0){

            const planos = this.$store.state.plano_participante.filter(plano => parseInt(plano.id_plano) == id_plano );
            var plano = planos[0];
            
            return this.$store.state.tipos_req.filter(function(tipo_req) {
              var exibe = true; 
              if(tipo_req.id_plano == id_plano ) {

                if(tipo_req.id == 1 || tipo_req.id == 3 || tipo_req.id == 7 || tipo_req.id == 13){
                    exibe = true;
                }
                else{
                      
                    if(tipo_req.id == 4 || tipo_req.id == 5 ){
                      if(plano.id_categoria == 1 || plano.id_categoria == 2 || plano.id_categoria == 3 || plano.id_categoria == 4 ){
                        exibe = false;
                      }else{
                        exibe = true;
                      }
                      
                    }else{
                      exibe = true;  
                    }  

                }
                
              }
              return exibe;
            })

          }else{

            return this.$store.state.tipos_req.filter(function(tipo_req) {
              var exibe = false;
              if(tipo_req.id_plano == id_plano ) {

                if(tipo_req.id == 4 || tipo_req.id == 5 ){
                    exibe = true;
                }
                
              }
              return exibe;
            })

          }
          
        },


        SetDocumentosNecessarios(documentos, id_tipo_requerimento){

          var documentosTodos = this.$store.state.docs_obrigatorios;

          var documentosNecessarios =  documentosTodos.filter(function(documento) {
                var exibe = false;
                if(documento.id_tipo_requerimento == id_tipo_requerimento ) {
                  exibe = true;
                }
                return exibe;
              });

          var documento_aux = null; 

          for( var i = 0; i < documentosNecessarios.length; i++){ 

            for( var j = 0; j < documentos.length; j++){ 

              if(documentosNecessarios[i].id_tipo_documento == documentos[j].id_tipo_documento){
                 documentosNecessarios[i].arquivo = documentos[j].arquivo;
                 documentosNecessarios[i].desc_situacao = documentos[j].desc_situacao;
                 documentosNecessarios[i].id_situacao = documentos[j].id_situacao;
                 documentosNecessarios[i].id_documento = documentos[j].id_documento;
                 documentosNecessarios[i].id_documento_ged = documentos[j].id_documento_ged;
                 documentosNecessarios[i].id_req_doc = documentos[j].id_req_doc;


              }

            }

          }

          return documentosNecessarios;

        },
        
        
        //FiltraDocsObrigatorios: function(id_tipo_requerimento, representante_legal){

        FiltraDocsBeneficiarios: function(id_tipo_requerimento){
          var requerimento = this.requerimento;
            return this.documentosNecessarios.filter(function(documento) {
              var exibe = false;
              if(documento.id_tipo_requerimento == id_tipo_requerimento ) {
                if(documento.regra == "2"){
                    if(id_tipo_requerimento == '1'){
                      exibe = true;
                    }
                }
                else{
                  exibe = false;
                }
                
              }
              return exibe;
            })
        },


        

        //#####################
        //click dos botões da tela
        //####################

        voltar(){
          
          if(this.tela > 1){
             this.tela -- ;

             if(this.requerimento.id_tipo_requerimento != 1){
                this.tela -- ;
             }
          }else{
            this.$router.push('/');
          }
        },
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        },   
        
        BtnSim(titulo){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          

          if(titulo == 'Alerta'){
           this.sair();
          }
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 



        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        }, 





        salvar(){

          this.loading = true;


          let data = {
              id: this.requerimento.id,
              id_nuvem: this.$store.state.nuvem.id,
              usuario:  this.$store.state.cpf,
              aceito: this.aceito,
          };
          
        

          this.$store.state.API.post( '/api/requerimento/aceito', data)
          .then(response => {
              if(response.data.codigo == 'ok'){

                if(response.data.situacao == 'N'){

                  this.$store.commit('setalerta','Requerimento cancelado com sucesso!');
                  this.msg_exigencia ="";
                  this.msg_detalhes = "O requerimento teve sua situação alterada para CANCELADO." 
                  this.GravaMensagem();
                }else{

                  this.$store.commit('setalerta','Requerimento enviado para alteração do valor do benefício!');
                  this.msg_exigencia ="";
                  this.msg_detalhes = "O novo valor do benefício foi APROVADO pelo participante." 
                  this.GravaMensagem();

                  let email_data = {
                    nome: this.nome_participante,
                    cpf: this.cpf_participante,
                    situacao: response.data.situacao,
                  };

                  this.$store.state.API.post('/api/requerimento/emailrecadbnf', email_data);

                }

              }


          })
          .catch(error => {
              this.TrataErros(error)
          });
          

        },


        GravaMensagem(){

          this.loading = true;

          if(this.msg_detalhes != ''){
            this.msg_detalhes = ' '+this.msg_detalhes;
          }

          var data = {
            id_requerimento: this.requerimento.id,
            msg: this.msg_exigencia + this.msg_detalhes
          };

          this.$store.state.API.post('/api/requerimento/mensagem/gravar', data)
          .then(response => {
            
            if(response.data.codigo == 'ok'){
              this.$router.push('/');
            }else{
              console.log(response.data.error_description);
              this.loading = false;
              this.sim_nao = false;
              this.titulo = 'Alerta';
              this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
              this.dialog = true;
            }  
          })/*
          .catch(error => {
              this.TrataErros(error);
          }); */

        },



        CarregaRequerimento(id){
            this.loading = true;
            this.$store.state.API.get( '/api/requerimento/show2/'+id )
            .then(response => {
                if(response.data.codigo == 'ok'){
                  this.requerimento = response.data.requerimento;
                  this.requerimento.id_plano = parseInt(this.requerimento.id_plano);
                  this.requerimento.id_tipo_requerimento = parseInt(this.requerimento.id_tipo);
                  this.dependentes = response.data.dependentes;
                  var situacao = this.situacoes.find(situacao => situacao.id ===this.requerimento.situacao);
                  this.requerimento.desc_situacao = situacao.descricao;
                  this.mensagens = response.data.mensagens;
                  var tipo_req = this.$store.state.tipos_req.find(element => element.id == this.requerimento.id_tipo_requerimento);
                  this.descricao_tipo_requerimento = tipo_req.descricao;

                  
                  for (var i = 0; i < response.data.detalhes.length; i++) {

                    if(response.data.detalhes[i].nome == 'novo_valor_bnf'){
                      this.novo_valor_bnf = response.data.detalhes[i].valor;
                    }
                    if(response.data.detalhes[i].nome == 'email_pessoal'){
                      this.email_pessoal = response.data.detalhes[i].valor;
                    }

                  }  

                  if(this.novo_valor_bnf == null){
                    this.novo_valor_bnf = '';
                  }


                  
                  this.loading = false;
                }else{
                  this.loading = false;
                }

                
                
            })
            .catch(error => {
                this.TrataErros(error)
            });
        },


        EscolheOuEnvia(id_tipo_documento, id_req_doc){
          this.exibe_escolha = true;
          this.id_tipo_documento = id_tipo_documento;
          this.id_req_doc = id_req_doc;
          this.id_req_bnf_doc = null;
          this.id_dependente = null;
        },


        EscolheOuEnviaBnf(id_tipo_documento, id_req_bnf_doc, id_dependente){
          this.exibe_escolha = true;
          this.id_tipo_documento = id_tipo_documento;
          this.id_req_doc = null;
          this.id_req_bnf_doc = id_req_bnf_doc;
          this.id_dependente = id_dependente;
        },


        EscolheComplementar(){
          this.exibe_escolha_complementar = true;
        },

        cancelarEscolha(){
          this.exibe_escolha = false;
          this.exibe_escolha_complementar = false;
          this.id_tipo_documento = null;
          this.id_req_doc = null;
          this.id_req_bnf_doc = null;
        },


        CarregaArquivo(){

          if(this.escolhido == 1){
            this.selecionarArquivo();
          }else{
            if(this.id_req_doc != null){
                this.$router.push('/docsportipo/'+this.id_tipo_documento+'/'+this.requerimento.id+'/'+this.id_req_doc+'/1');
            }else{
                this.$router.push('/docsbnfportipo/'+this.id_tipo_documento+'/'+this.requerimento.id+'/'+this.id_req_bnf_doc+'/'+this.id_dependente+'/1');
            }
            
          }

        },

        CarregaArquivoComplementar(){

          if(this.escolhido == 1){
            this.$router.push('/novocomplementar/'+this.requerimento.id);
          }else{
            this.$router.push('/docs/'+this.requerimento.id);
          }

        },


        selecionarArquivo() {
          //console.log(this.id_tipo_documento);
          if(this.$refs.form.validate() ){
            document.getElementById("fileUpload1").click();
          }  
        },




        enviarArquivo() {

          if(event.target.files[0].size <= this.$store.state.max_file_size * 1048576 ){

            this.loading = true;

            let formData = new FormData();
            formData.append('id_nuvem', this.$store.state.nuvem.id);
            formData.append('id_tipo', 405);
            formData.append('id_tipo_documento',this.id_tipo_documento); 
            formData.append('id_protocolo_ged',this.$store.state.nuvem.id_protocolo_ged);
            formData.append('token_ged',this.$store.state.token_ged);
            formData.append('arquivo', this.arquivo);
            formData.append('usuario', this.$store.state.cpf);
            formData.append('id_origem', 1);
            formData.append('escopo', 'P');

            this.$store.state.API.post( '/api/novodocumento',  formData)
            .then(response => {
              if(response.data.codigo == 'ok'){
              
                if(this.id_dependente == null){
                  this.atualizaDocumento(this.id_req_doc, response.data.id_documento, this.id_tipo_documento);
                }else{
                  this.atualizaDocumentoBnf(this.id_req_bnf_doc, response.data.id_documento);
                }
                

              }
            })
            .catch(error => {
                this.TrataErros(error);

            }); 
          }else{
            this.alerta = 'Documento muito grande! Tamanho máximo: '+this.$store.state.max_file_size+' MB';
            this.alerta_color = '#B00020';
            this.exibe_alerta = true;
          }

        },

        atualizaDocumento(id_req_doc, id_documento, id_tipo_documento){


          let data = {
                id_req_doc: id_req_doc,
                id_documento: id_documento,
                id_situacao: 4,
                usuario: this.$store.state.cpf,
                id_nuvem: this.$store.state.nuvem.id,
                id_requerimento: this.requerimento.id,
                id_tipo_documento: id_tipo_documento
              };


          this.$store.state.API.post( '/api/requerimento/atualizadocumento',  data)
          .then(response => {
              if(response.data.codigo == 'ok'){

                this.alerta = 'Documento gravado com sucesso!';
                this.alerta_color = "green";

                const index = this.documentosNecessarios.findIndex((documento, index) => {
                  if (documento.id_tipo_documento == id_tipo_documento) {
                    return true
                  }
                });
                //console.log(this.arquivo);

                this.documentosNecessarios[index].arquivo = this.arquivo.name;
                this.documentosNecessarios[index].desc_situacao = "EM ANÁLISE";
                this.documentosNecessarios[index].id_documento = id_documento;
                this.documentosNecessarios[index].id_situacao = 4;

                this.VerificaSePodeEnviar();

                this.exibe_escolha = false;
                this.id_tipo_documento = null;
                this.id_req_doc = null;  
                this.exibe_alerta = true; 
                this.arquivo = null; 
                this.loading  = false;    
        
              }else{
                this.loading = false;
              }
              
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },


        atualizaDocumentoBnf(id_req_bnf_doc, id_documento){

          let data = {
              id_req_bnf_doc: id_req_bnf_doc,
              id_requerimento: this.requerimento.id,
              id_tipo_documento: this.id_tipo_documento,
              id_documento: id_documento,
              id_situacao: 4,
              usuario: this.$store.state.cpf,
              id_nuvem: this.$store.state.nuvem.id,
              id_beneficiario: this.id_dependente,
          };

          this.$store.state.API.post( '/api/requerimento/atualizadocumentobnf', data )
          .then(response => {

            if(response.data.codigo == 'ok'){
                this.alerta_color = "green";
                this.alerta = "Documento gravado com sucesso!";

                const index = this.dependentes.findIndex(dependente => dependente.id == this.id_dependente);
                const index_doc = this.dependentes[index].docs_obrigatorios.findIndex(documento => documento.id_tipo_documento == this.id_tipo_documento);
                
                this.dependentes[index].docs_obrigatorios[index_doc].arquivo = this.arquivo.name;
                this.dependentes[index].docs_obrigatorios[index_doc].desc_situacao = "EM ANÁLISE";
                this.dependentes[index].docs_obrigatorios[index_doc].id_documento = id_documento;
                this.dependentes[index].docs_obrigatorios[index_doc].id_situacao = 4;
                
                
                this.VerificaSePodeEnviar();

                this.exibe_escolha = false;
                this.id_tipo_documento = null;
                this.id_req_doc = null;  
                this.exibe_alerta = true; 
                this.arquivo = null; 
                this.loading  = false;  
            }
            
          })
          .catch(error => {
              this.TrataErros(error);
          }); 

        },


        VerificaSePodeEnviar(){
        
          this.pode_enviar = false;

          if(this.requerimento.situacao == 'L' || this.requerimento.situacao == 'E'){

            var pode = true;
            var documentos = this.FiltraDocsObrigatorios(this.requerimento.id_tipo_requerimento);
            for (var i = 0; i < documentos.length; i++) {
                if(documentos[i].id_situacao != 1 && documentos[i].id_situacao != 4 && documentos[i].id_situacao != 5){

                  if(documentos[i].regra == 1 || documentos[i].regra == 2 || documentos[i].regra == 8 || documentos[i].regra == 12 || documentos[i].regra == 13 ){
                    
                  }else if(documentos[i].regra == 9 ){
                    if(!documentos[i].id_situacao){
                        pode = false;
                    }
                  }else{
                    pode  = false;
                  }
                }
            }

            let dep_len = this.dependentes.length;
            for(let i=0; i<dep_len; i++){
              let doc_len = this.dependentes[i].docs_obrigatorios.length;
              for(let x=0; x<doc_len; x++){
                if(this.dependentes[i].docs_obrigatorios[x].id_documento == null){
                  pode = false;
                }
              }
            }

          }

          this.pode_enviar = pode;
         
        },



        excluirComplementar(id_complementar){

          this.excluindo = true;

          let data = {
              id: id_complementar,
              id_nuvem: this.$store.state.nuvem.id,
              usuario: this.$store.state.cpf
            };

          this.$store.state.API.post( '/api/requerimento/complementar/excluir',  data)
          .then(response => {
              if(response.data.codigo == 'ok'){
                for( var i = 0; i < this.documentosComplementares.length; i++){ 
                    if ( this.documentosComplementares[i].id === id_complementar) { 
                        this.documentosComplementares.splice(i, 1); 
                    }
                
                }
              }
              this.excluindo = false;
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },


        recuperaTabelas() {

          //busca a pessoa

          this.$store.state.API.get( '/api/tabelas')
          .then(response => {

              this.planos = response.data.plano;                    
              this.nacionalidade = response.data.nacionalidade;
              this.estado_civil = response.data.estadoCivil; 
              this.escolaridade = response.data.escolaridade;
              this.tipoTempoServico = response.data.tipoTempoServico;
              this.parentesco = response.data.parentesco;
              this.paises = response.data.paises; 
            

          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 

        },


        recuperaSalario() {
          //busca a pessoa
          let data = {
            cpf: this.cpf_participante,
          }
          this.$store.state.API.post( '/api/salariops2/', data)
          .then(response => {
              this.salariops2 = response.data;
          });

        },




        //#####################
        //funções utilitárias
        //####################

        idade(data_nascimento, data_atual) {
                
                var ano_atual = parseInt(data_atual.substr(0,4)),
                mes_atual = parseInt(data_atual.substr(5,2)),
                dia_atual = parseInt(data_atual.substr(8,2)),
                ano_aniversario = parseInt(data_nascimento.substr(0,4)),
                mes_aniversario = parseInt(data_nascimento.substr(5,2)),
                dia_aniversario = parseInt(data_nascimento.substr(8,2)),


        

                ano_aniversario = +ano_aniversario,
                mes_aniversario = +mes_aniversario,
                dia_aniversario = +dia_aniversario,

                quantos_anos = ano_atual - ano_aniversario;

    


            if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
                quantos_anos--;
            }

            return quantos_anos < 0 ? 0 : quantos_anos;  

        },


        listaDependentes(dependentes){

          let data = {
              id_pessoa: this.id_pessoa,
              id_plano: this.requerimento.id_plano
            };

          this.carregando_pensionistas = true;
          this.$store.state.API.post( '/api/requerimento/dependentes', data)
          .then(response => {
            this.pensionistas_trust = response.data;

            let len = this.pensionistas_trust.length;
            let len2 = this.dependentes.length;
            for(let i=0; i<len; i++){
              this.pensionistas_trust[i].selecionado = false;
              for(let j=0; j<len2; j++){
                if(this.pensionistas_trust[i].nr_cnpj_cpf == dependentes[j].cpf){
                  this.pensionistas_trust[i].selecionado = true;
                }
              }
            }

            this.carregando_pensionistas = false;
            
          })
          .catch(error => {
              console.log(error);
            
          }); 
        },

        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        }
        

      }

      
   }
</script>